import React, { useState } from 'react'
import { Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation, us, useNavigateeParams } from 'react-router-dom';
import {api} from "../utils/constants";

export const AddOmraHajj = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [type, setType] = useState("");
  const [Image, setImage] = useState(null);
  const [dateAller, setDateAller] = useState("");
  const [dateRetour, setDateRetour] = useState("");
  const [hotelMedina, setHotelMedina] = useState("");
  const [checkInHM, setCheckInHM] = useState("");
  const [checkOutHM, setCheckOutHM] = useState("");
  const [hotelMakkah, setHotelMakkah] = useState("");
  const [checkInHK, setCheckInHK] = useState("");
  const [checkOutHK, setCheckOutHK] = useState("");

  // Information vols aller
  const [allerCompany, setAllerCompany] = useState("");
  const [allerVilleDepart, setAllerVilleDepart] = useState("");
  const [allerVilleArrive, setAllerVilleArrive] = useState("");
  const [allerDateDepart, setAllerDateDepart] = useState("");
  const [allerDateArrive, setAllerDateArrive] = useState("");
  const [allerHeureDepart, setAllerHeureDepart] = useState("");
  const [allerHeureArrive, setAllerHeureArrive] = useState("");
  const [isEscaleAllerChecked, setIsEscaleAllerChecked] = useState(false);
  const [allerVilleEscale, setAllerVilleEscale] = useState("");
  const [allerDateEscale, setAllerDateEscale] = useState("");
  const [allerHeureEscale, setAllerHeureEscale] = useState("");
  const [allerDateReprise, setAllerDateReprise] = useState("");
  const [allerHeureReprise, setAllerHeureReprise] = useState("");

  // Informations vols retour
  const [retourCompany, setRetourCompany] = useState("");
  const [retourVilleDepart, setRetourVilleDepart] = useState("");
  const [retourVilleArrive, setRetourVilleArrive] = useState("");
  const [retourDateDepart, setRetourDateDepart] = useState("");
  const [retourDateArrive, setRetourDateArrive] = useState("");
  const [retourHeureDepart, setRetourHeureDepart] = useState("");
  const [retourHeureArrive, setRetourHeureArrive] = useState("");
  const [isEscaleRetourChecked, setIsEscaleRetourChecked] = useState(false);
  const [retourVilleEscale, setRetourVilleEscale] = useState("");
  const [retourDateEscale, setRetourDateEscale] = useState("");
  const [retourHeureEscale, setRetourHeureEscale] = useState("");
  const [retourDateReprise, setRetourDateReprise] = useState("");
  const [retourHeureReprise, setRetourHeureReprise] = useState("");

  const [singlePrice, setSinglePrice] = useState("");
  const [doublePrice, setDoublePrice] = useState("");
  const [triplePrice, setTriplePrice] = useState("");
  const [quadPrice, setQuadPrice] = useState("");

  const [isPetitDejChecked, setIsPetitDejChecked] = useState(false);
  const [isDejChecked, setIsDejChecked] = useState(false);
  const [isDinnerChecked, setIsDinnerChecked] = useState(false);

  const [isAutreMakkahChecked, setIsAutreMakkahChecked] = useState(false);
  const [isAutreMadinahChecked, setIsAutreMadinahChecked] = useState(false);
  const [isDepartVolChecked, setIsDepartVolChecked] = useState(false);
  const [isRetourVolChecked, setIsRetourVolChecked] = useState(false);

  const navigate = useNavigate();

  const handleHotelMadinah = (e) => {
    setHotelMedina(e.target.value);
    if(e.target.value === "Autre"){
      setIsAutreMadinahChecked(true);
      setHotelMedina("");
    }
    else{
      setIsAutreMadinahChecked(false)
    }
  };

  const handleHotelMakkah = (e) => {
    if(e.target.value === "Autre"){
      setIsAutreMakkahChecked(true);
      setHotelMakkah("");
    }
    else{
      setHotelMakkah(e.target.value);
      setIsAutreMakkahChecked(false)
    }
  };

  const handleEscaleAllerChange = (e) => {
    setIsEscaleAllerChecked(e.target.checked);
  };
  const handleEscaleRetourChange = (e) => {
    setIsEscaleRetourChecked(e.target.checked);
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  const handlePackImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    const formData = new FormData();
    formData.append('label', label);
    formData.append('type', type);
    formData.append('image', Image);
    formData.append('date_aller', dateAller);
    formData.append('date_retour', dateRetour);
    formData.append('check_in_hm', checkInHM);
    formData.append('check_out_hm', checkOutHM);
    formData.append('check_in_hk', checkInHK);
    formData.append('check_out_hk', checkOutHK);
    formData.append('hotel_medina', hotelMedina);
    formData.append('hotel_makkah', hotelMakkah);
    formData.append('aller_company', allerCompany);
    formData.append('aller_ville_depart', allerVilleDepart);
    formData.append('aller_ville_arrive', allerVilleArrive);
    formData.append('aller_date_depart', allerDateDepart);
    formData.append('aller_date_arrive', allerDateArrive);
    formData.append('aller_heure_depart', allerHeureDepart);
    formData.append('aller_heure_arrive', allerHeureArrive);
    formData.append('escale_aller', isEscaleAllerChecked);
    formData.append('aller_ville_escale', allerVilleEscale);
    formData.append('aller_date_escale', allerDateEscale);
    formData.append('aller_heure_escale', allerHeureEscale);
    formData.append('aller_date_reprise', allerDateReprise);
    formData.append('aller_heure_reprise', allerHeureReprise);
    formData.append('retour_company', retourCompany);
    formData.append('retour_ville_depart', retourVilleDepart);
    formData.append('retour_ville_arrive', retourVilleArrive);
    formData.append('retour_date_depart', retourDateDepart);
    formData.append('retour_date_arrive', retourDateArrive);
    formData.append('retour_heure_depart', retourHeureDepart);
    formData.append('retour_heure_arrive', retourHeureArrive);
    formData.append('escale_retour', isEscaleRetourChecked);
    formData.append('retour_ville_escale', retourVilleEscale);
    formData.append('retour_date_escale', retourDateEscale);
    formData.append('retour_heure_escale', retourHeureEscale);
    formData.append('retour_date_reprise', retourDateReprise);
    formData.append('retour_heure_reprise', retourHeureReprise);
    formData.append('single_price', singlePrice);
    formData.append('double_price', doublePrice);
    formData.append('triple_price', triplePrice);
    formData.append('quad_price', quadPrice);
    formData.append('petit_dejeuner', isPetitDejChecked);
    formData.append('dejeuner', isDejChecked);
    formData.append('dinner', isDinnerChecked);

    try {
      const response = await api.post('omrahajjpacks/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        navigate('/omra-hajj-packs');
        toast.success("Pack ajouté avec succès !");
        setLabel("");
        setType("");
        setImage(null);
        setDateAller("");
        setDateRetour("");
        setCheckInHM("");
        setCheckOutHM("");
        setCheckInHK("");
        setCheckOutHK("");
        setHotelMedina("");
        setHotelMakkah("");
        setAllerCompany("");
        setAllerVilleDepart("");
        setAllerVilleArrive("");
        setAllerDateDepart("");
        setAllerDateArrive("");
        setAllerHeureDepart("");
        setAllerHeureArrive("");
        setIsEscaleAllerChecked(false);
        setAllerVilleEscale("");
        setAllerDateEscale("");
        setAllerHeureEscale("");
        setAllerDateReprise("");
        setAllerHeureReprise("");
        setRetourCompany("");
        setRetourVilleDepart("");
        setRetourVilleArrive("");
        setRetourDateDepart("");
        setRetourDateArrive("");
        setRetourHeureDepart("");
        setRetourHeureArrive("");
        setIsEscaleRetourChecked(false);
        setRetourVilleEscale("");
        setRetourDateEscale("");
        setRetourHeureEscale("");
        setRetourDateReprise("");
        setRetourHeureReprise("");
        setSinglePrice("");
        setDoublePrice("");
        setTriplePrice("");
        setQuadPrice("");
        setIsPetitDejChecked(false);
        setIsDejChecked(false);
        setIsDinnerChecked(false);
        setLoading(false);

      } else {
        setLoading(false);
        console.error('Failed to add pack');
        setErrorMessage(
          "Une erreur s'est produite lors de l'ajout de pack'. Veuillez réessayer."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
      setErrorMessage(
        "Une erreur s'est produite lors de l'ajout de pack'. Veuillez réessayer."
      );
    }
  };
  

  return (
    <div className='container'>
      <ToastContainer/>
        <form onSubmit={handleFormSubmit}>
            <div className='row mb-4'>
              <div className='col'>
                <button className='btn btn-danger' onClick={() => navigate('/omra-hajj-packs')}>Retour</button>
              </div>
              <div className='col d-flex justify-content-center'>
                <h4 className='text-center mt-1'>Informations pack</h4>
              </div>
              <div className='col'>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                  <div class="form-group">
                    <label for="label">Libellé du pack</label>
                    <input type="text" class="form-control" value={label} onChange={(e) => setLabel(e.target.value)} id="label" placeholder="Libellé" />
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="typePack">Type du pack</label>
                    <Select className="w-100 text-center" value={type} onChange={handleTypeChange} displayEmpty>
                      <MenuItem value="">-- Select --</MenuItem> 
                      <MenuItem value="omra">Omra</MenuItem> 
                      <MenuItem value="hajj">Hajj</MenuItem> 
                    </Select> 
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="img-pack">Image</label>
                    <input type="file" class="form-control-file" id="img-pack" onChange={handlePackImageChange}/>
                  </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                  <div class="form-group">
                    <label for="dateAller">Date aller</label>
                    <input type="date" value={dateAller} onChange={(e) => setDateAller(e.target.value)} class="form-control" id="dateAller"/>
                  </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="dateRetour">Date retour</label>
                    <input type="date" value={dateRetour} onChange={(e) => setDateRetour(e.target.value)} class="form-control" id="dateRetour"/>
                  </div>
              </div>

            </div>
            <hr/>
            <h5 className='text-center'>Hotel Médine</h5>
            <div className='row'>

              <div className='col'>
                <div class="form-group">
                  <label for="hotel">Hôtel</label><br/>
                  <Select className="w-100 text-center" value={hotelMedina} onChange={(e) => handleHotelMadinah(e)} id="hotel" displayEmpty>
                    <MenuItem value="">-- Select --</MenuItem> 
                    <MenuItem value="Millennium al Aqeeq 5*">Millennium al Aqeeq 5*</MenuItem> 
                    <MenuItem value="Madinah Concorde hotel 3*">Madinah Concorde hotel 3*</MenuItem> 
                    <MenuItem value="Nusuk Al Madinah hotel 3.5*">Nusuk Al Madinah hotel 3.5*</MenuItem> 
                    <MenuItem value="Un hôtel exceptionnel">Un hôtel exceptionnel</MenuItem>
                    <MenuItem value="Autre">Autre</MenuItem>  
                  </Select>               
                  <div class="form-check">
                    <input class="form-check-input" checked={isAutreMadinahChecked} onChange={(e) => setIsAutreMadinahChecked(e.target.checked)} type="checkbox" id="autreMadina"/>
                    <label class="form-check-label" for="autreMadina">
                      Autre
                    </label>
                  </div>
                  {
                    isAutreMadinahChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" placeholder="Saisir le nom de l'hôtel" id="" value={hotelMedina} onChange={(e) => setHotelMedina(e.target.value)}/>
                      </div>
                    )
                  }
                </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="checkin-medina">Check-in</label>
                    <input type="date" class="form-control" id="checkin-medina" value={checkInHM} onChange={(e) => setCheckInHM(e.target.value)}/>
                  </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="checkout-medina">Check-out</label>
                    <input type="date" class="form-control" value={checkOutHM} onChange={(e) => setCheckOutHM(e.target.value)} id="checkout-medina"/>
                  </div>
              </div>
            </div>

            <hr/>
            <h5 className='text-center'>Hotel Makkah</h5>
            <div className='row'>

              <div className='col'>
                <div class="form-group">
                  <label for="hotelMakkah">Hôtel</label><br/>
                  <Select className="w-100 text-center" id="hotelMakkah" value={hotelMakkah} onChange={(e) => handleHotelMakkah(e)} displayEmpty>
                    <MenuItem value="">-- Select --</MenuItem> 
                    <MenuItem value="Hotel Marriott 5*">Hotel Marriott 5*</MenuItem> 
                    <MenuItem value="Hilton Suit 5*">Hilton Suit 5*</MenuItem> 
                    <MenuItem value="Conrad 5*">Conrad 5*</MenuItem> 
                    <MenuItem value="Hotel Sheraton 4*">Hotel Sheraton 4*</MenuItem> 
                    <MenuItem value="Un hôtel exceptionnel">Un hôtel exceptionnel</MenuItem>
                    <MenuItem value="Autre">Autre</MenuItem>  
                  </Select>               
                  <div class="form-check">
                    <input class="form-check-input" checked={isAutreMakkahChecked} onChange={(e) => setIsAutreMakkahChecked(e.target.checked)} type="checkbox" id="autreMakkah"/>
                    <label class="form-check-label" for="autreMakkah">
                      Autre
                    </label>
                  </div>
                  {
                    isAutreMakkahChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" id="" placeholder="Saisir le nom de l'hôtel" value={hotelMakkah} onChange={(e) => setHotelMakkah(e.target.value)}/>
                      </div>
                    )
                  }
                </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="checkin-makkah">Check-in</label>
                    <input type="date" class="form-control" id="checkin-makkah" value={checkInHK} onChange={(e) => setCheckInHK(e.target.value)}/>
                  </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="checkout-makkah">Check-out</label>
                    <input type="date" class="form-control" id="checkout-makkah" value={checkOutHK} onChange={(e) => setCheckOutHK(e.target.value)}/>
                  </div>
              </div>
            </div>

            <hr/>
            <h5 className='text-center'>Informations vols</h5>
            <p><b>DÉPART :</b></p>
            <div className='row mt-3'>
              <div className='col'>
                <div class="form-group">
                  <label for="aller-company">Compagnies aériennes</label><br/>
                  <Select className="w-100 text-center" value={allerCompany} onChange={(e) => setAllerCompany(e.target.value)} id="aller-company" displayEmpty>
                    <MenuItem value="">-- Select --</MenuItem> 
                    <MenuItem value="SAUDIA AIRLINES">SAUDIA AIRLINES</MenuItem>
                    <MenuItem value="TURKISH AIRLINES">TURKISH AIRLINES</MenuItem>       
                    <MenuItem value="ROYAL JORDANIAN">ROYAL JORDANIAN</MenuItem> 
                    <MenuItem value="EgyptAir">EgyptAir</MenuItem> 
                  </Select>    
                  <div class="form-check">
                    <input class="form-check-input" checked={isDepartVolChecked} onChange={(e) => setIsDepartVolChecked(e.target.checked)} type="checkbox" id="autreAllerVol"/>
                    <label class="form-check-label" for="autreAllerVol">
                      Autre
                    </label>
                  </div>
                  {
                    isDepartVolChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" placeholder="Saisir la Compagnie aérienne" id="" value={allerCompany} onChange={(e) => setAllerCompany(e.target.value)}/>
                      </div>
                    )
                  }           
                </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="villeAllerD">Ville de départ</label>
                    <input type="text" class="form-control" value={allerVilleDepart} onChange={(e) => setAllerVilleDepart(e.target.value)} id="villeAllerD"/>
                  </div>
              </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="villeAllerA">Ville d'arrivée</label>
                    <input type="text" class="form-control" value={allerVilleArrive} onChange={(e) => setAllerVilleArrive(e.target.value)} id="villeAllerA"/>
                  </div>
            </div>
          </div>
          <div className='row mt-3'>
              <div className='col'>
                  <div class="form-group">
                    <label for="allerDateDepart">Date du départ</label>
                    <input type="date" class="form-control" id="allerDateDepart" value={allerDateDepart} onChange={(e) => setAllerDateDepart(e.target.value)}/>
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="allerHeureDepart">Heure du départ</label>
                    <input type="time" class="form-control" id="allerHeureDepart" value={allerHeureDepart} onChange={(e) => setAllerHeureDepart(e.target.value)}/>
                  </div>
              </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="allerDateArrive">Date d'arrivée</label>
                    <input type="date" class="form-control" id="allerDateArrive" value={allerDateArrive} onChange={(e) => setAllerDateArrive(e.target.value)}/>
                  </div>
            </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="allerHeureArrive">Heure d'arrivée</label>
                    <input type="time" class="form-control" id="allerHeureArrive" value={allerHeureArrive} onChange={(e) => setAllerHeureArrive(e.target.value)}/>
                  </div>
            </div>
          </div>
          <div className='escale'>
            <div class="form-check">
              <input class="form-check-input" checked={isEscaleAllerChecked} onChange={handleEscaleAllerChange} type="checkbox" id="allerEscale"/>
              <label class="form-check-label" for="allerEscale">
                Escale (Voyage Aller)
              </label>
            </div>
          </div>
          {isEscaleAllerChecked && (
          <>
          <div className='row mt-2'>
            <div className='col'>
              <div class="form-group">
                <label for="allerVilleEscale">Ville d'escale</label>
                <input type="text" class="form-control" placeholder="Ville d'escale" id="allerVilleEscale" value={allerVilleEscale} onChange={(e) => setAllerVilleEscale(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
              <div className='col'>
                  <div class="form-group">
                    <label for="allerDateEscale">Date d'escale</label>
                    <input type="date" class="form-control" id="allerVilleEscale" value={allerDateEscale} onChange={(e) => setAllerDateEscale(e.target.value)}/>
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="allerHeureEscale">Heure d'escale</label>
                    <input type="time" class="form-control" id="allerHeureEscale" value={allerHeureEscale} onChange={(e) => setAllerHeureEscale(e.target.value)}/>
                  </div>
              </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="allerRepriseVol">Date du reprise de vol</label>
                    <input type="date" class="form-control" id="allerRepriseVol" value={allerDateReprise} onChange={(e) => setAllerDateReprise(e.target.value)}/>
                  </div>
            </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="allerHeureReprise">Heure du reprise de vol</label>
                    <input type="time" class="form-control" id="allerHeureReprise" value={allerHeureReprise} onChange={(e) => setAllerHeureReprise(e.target.value)}/>
                  </div>
            </div>
          </div>
          </>
          )}
          <hr/>
          <p><b>RETOUR :</b></p>
            <div className='row mt-3'>
              <div className='col'>
                <div class="form-group">
                  <label for="retourCompany">Compagnies aériennes</label><br/>
                  <Select id="retourCompany" className="w-100 text-center" value={retourCompany} onChange={(e) => setRetourCompany(e.target.value)} displayEmpty>
                    <MenuItem value="">-- Select --</MenuItem> 
                    <MenuItem value="SAUDIA AIRLINES">SAUDIA AIRLINES</MenuItem>
                    <MenuItem value="TURKISH AIRLINES">TURKISH AIRLINES</MenuItem>       
                    <MenuItem value="ROYAL JORDANIAN">ROYAL JORDANIAN</MenuItem> 
                    <MenuItem value="EGYPT AIR">EGYPT AIR</MenuItem> 
                  </Select>             
                  <div class="form-check">
                    <input class="form-check-input" checked={isRetourVolChecked} onChange={(e) => setIsRetourVolChecked(e.target.checked)} type="checkbox" id="autreRetourVol"/>
                    <label class="form-check-label" for="autreRetourVol">
                      Autre
                    </label>
                  </div>
                  {
                    isRetourVolChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" placeholder="Saisir la Compagnie aérienne" id="" value={retourCompany} onChange={(e) => setRetourCompany(e.target.value)}/>
                      </div>
                    )
                  }        
                </div>
              </div>

              <div className='col'>
                  <div class="form-group">
                    <label for="retourVilleDepart">Ville de départ</label>
                    <input type="text" class="form-control" id="retourVilleDepart" value={retourVilleDepart} onChange={(e) => setRetourVilleDepart(e.target.value)}/>
                  </div>
              </div>
            <div className='col'>
                  <div className="form-group">
                    <label for="retourVilleArrive">Ville d'arrivée</label>
                    <input type="text" class="form-control" id="retourVilleArrive" value={retourVilleArrive} onChange={(e) => setRetourVilleArrive(e.target.value)}/>
                  </div>
            </div>
          </div>
          <div className='row mt-3'>
              <div className='col'>
                  <div class="form-group">
                    <label for="retourDateDepart">Date du départ</label>
                    <input type="date" class="form-control" id="retourDateDepart" value={retourDateDepart} onChange={(e) => setRetourDateDepart(e.target.value)}/>
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="retourHeureDepart">Heure du départ</label>
                    <input type="time" class="form-control" id="retourHeureDepart" value={retourHeureDepart} onChange={(e) => setRetourHeureDepart(e.target.value)}/>
                  </div>
              </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="retourDateArrive">Date d'arrivée</label>
                    <input type="date" class="form-control" id="retourDateArrive" value={retourDateArrive} onChange={(e) => setRetourDateArrive(e.target.value)}/>
                  </div>
            </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="retourHeureArrive">Heure d'arrivée</label>
                    <input type="time" class="form-control" id="retourHeureArrive" value={retourHeureArrive} onChange={(e) => setRetourHeureArrive(e.target.value)}/>
                  </div>
            </div>
          </div>
          <div className='escale'>
            <div class="form-check">
              <input class="form-check-input" checked={isEscaleRetourChecked} onChange={handleEscaleRetourChange} type="checkbox" id="escaleRetour"/>
              <label class="form-check-label" for="escaleRetour">
                Escale (Voyage Retour)
              </label>
            </div>
          </div>
          {isEscaleRetourChecked && (
          <>
          <div className='row mt-2'>
            <div className='col'>
              <div class="form-group">
                <label for="retourVilleEscale">Ville d'escale</label>
                <input type="text" class="form-control" placeholder="Ville d'escale" id="retourVilleEscale" value={retourVilleEscale} onChange={(e) => setRetourVilleEscale(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
              <div className='col'>
                  <div class="form-group">
                    <label for="retourDateEscale">Date d'escale</label>
                    <input type="date" class="form-control" id="retourDateEscale" value={retourDateEscale} onChange={(e) => setRetourDateEscale(e.target.value)}/>
                  </div>
              </div>
              <div className='col'>
                  <div class="form-group">
                    <label for="retourHeureEscale">Heure d'escale</label>
                    <input type="time" class="form-control" id="retourHeureEscale" value={retourHeureEscale} onChange={(e) => setRetourHeureEscale(e.target.value)}/>
                  </div>
              </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="retourDateReprise">Date du reprise de vol</label>
                    <input type="date" class="form-control" id="retourDateReprise" value={retourDateReprise} onChange={(e) => setRetourDateReprise(e.target.value)}/>
                  </div>
            </div>
            <div className='col'>
                  <div class="form-group">
                    <label for="retourHeureReprise">Heure du reprise de vol</label>
                    <input type="time" class="form-control" id="retourHeureReprise" value={retourHeureReprise} onChange={(e) => setRetourHeureReprise(e.target.value)}/>
                  </div>
            </div>
          </div>
          </>
          )}
          <hr/>
          <h5>Tarifs (en €/personne)</h5>
          <div className='row mt-4'>
            <div className='col'>
              <div className="form-group">
                <label for="chambreSingle">Chambre single</label>
                  <input type="number" class="form-control" id="chambreSingle" value={singlePrice} onChange={(e) => setSinglePrice(e.target.value)}/>
                </div>
            </div>

            <div className='col'>
              <div className="form-group">
                <label for="chambreDouble">Chambre double</label>
                  <input type="number" class="form-control" id="chambreDouble" value={doublePrice} onChange={(e) => setDoublePrice(e.target.value)}/>
                </div>
            </div>

            <div className='col'>
              <div className="form-group">
                <label for="chambreTriple">Chambre triple</label>
                  <input type="number" class="form-control" id="chambreTriple" value={triplePrice} onChange={(e) => setTriplePrice(e.target.value)}/>
                </div>
            </div>

            <div className='col'>
              <div className="form-group">
                <label for="chambreQuadruple">Chambre quadruple</label>
                  <input type="number" class="form-control" id="chambreQuadruple" value={quadPrice} onChange={(e) => setQuadPrice(e.target.value)}/>
              </div>
            </div>
          </div>
          <hr/>
          <h5>Repas (Cocher les repas inclus)</h5>
          <div className = "row mt-3">
            <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isPetitDejChecked} onChange={(e) => setIsPetitDejChecked(e.target.checked)} type="checkbox" id="petit-dej"/>
                <label class="form-check-label" for="petit-dej">
                  Petit déjeûner
                </label>
              </div>
          </div>
            <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isDejChecked} onChange={(e) => setIsDejChecked(e.target.checked)} type="checkbox" id="dejeuner"/>
                <label class="form-check-label" for="dejeuner">
                Déjeûner
                </label>
              </div>
          </div>
          <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isDinnerChecked} onChange={(e) => setIsDinnerChecked(e.target.checked)} type="checkbox" id="dinner"/>
                <label class="form-check-label" for="dinner">
                Dinner
                </label>
              </div>
          </div>
          </div>
          <div className='row mt-3 mb-3'>
            <div className='col d-flex justify-content-center'>
              <button
                type="submit"
                className="btn w-100 btn-primary"
                disabled={loading}
              >
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
               Confirmer et ajouter le pack
              </button>
            </div>
          </div>
          <div className="row">
              <div className="col w-100">
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
            </div>
        </form>
    </div>
  )
}
