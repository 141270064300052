import React from "react";
import hajjinfoImg2 from "../images/hajjinfo/hajjinfo2.jpeg";
import hajjinfoImg3 from "../images/hajjinfo/hajjinfo3.jpeg";
import hajjinfoImg4 from "../images/hajjinfo/hajjinfo4.jpeg";
import hajjinfoImg5 from "../images/hajjinfo/hajjinfo5.jpeg";

const HajjInformation = () => {
  return (
    <div className="container hajj-info">
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Comment se fait l’inscription pour le Hajj 2024 ?</h2>
      <p>
        <strong>1ʳᵉ ÉTAPE</strong>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Chaque pèlerin doit impérativement s’inscrire sur le site NusukHajj, enregistrer ses documents d’identité et doit accéder à l’étape « En cours d’examen ». Pour accéder au site NusukHajj, cliquer sur le bouton ci-dessous :
        <br />
        <br />
        <div class="gdlr-core-pbf-element text-center">
    <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb">
        <a class="btn btn-orange btn-lg gdlr-core-button gdlr-core-button-gradient gdlr-core-button-no-border gdlr-core-button-full-width" href="https://hajj.nusuk.sa/" target="_blank">
            <span class="gdlr-core-content">NusukHajj</span>
            
        </a>
        <br />
            <br />
    </div>
</div>


      </p>
      <p>
        <strong>2ᵉ ÉTAPE</strong>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Se rattacher à notre agence Amctravels en remplissant notre formulaire d’inscription au Hajj 2024 pour être en lien direct avec notre agence de voyages ET bénéficier du statut PRIORITAIRE. Après avoir finalisé votre inscription sur NuskHajj.
      </p>

      <h2 style={{ color: 'brown', textAlign: 'center' }}>Guide complet du Hajj pour les pèlerins</h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Étant l'un des piliers fondamentaux de l'Islam, le Hajj peut être défini comme une expérience spirituelle annuelle entreprise par les musulmans au moins une fois dans leur vie pour exprimer leur servitude et leur foi envers Allah (SWT). Conformément à la Sunna du prophète Mahomet (PSL), vieille de 1,377 XNUMX ans, des millions de musulmans visitent la Sainte Kaaba chaque année pendant le mois islamique de Dhul-Hijjah pour accomplir le Hajj.
        <br />
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={hajjinfoImg2} alt="Description of the image" />
      </div>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Cependant, pour pouvoir accomplir le Hajj, un musulman doit être physiquement, mentalement et financièrement stable. La condition pour être financièrement et physiquement compétent pour accomplir le Hajj est appelée « istita'ah », où le musulman qui accomplit avec succès le voyage est appelé « Hajji » ou « mustati ».
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Allah (SWT) sur la signification du Hajj dit dans le Saint Coran : « Nous avons achevé le sanctuaire (Sainte Kaaba), un point focal pour le peuple et un sanctuaire sécurisé. Vous pouvez utiliser le sanctuaire d'Abraham comme maison de prière ; nous donnons le pouvoir à Abraham et Ismail. Tu nettoieras ma maison pour ceux qui font une visite et ceux qui y vivent et ceux qui hochent la tête et se prosternent. (2:125)
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Dans un autre endroit du Coran, Allah (SWT) déclare : « Et aux gens le Hajj. Ils viendront ici vers vous sur chaque chameau musclé : ils viendront ici de toutes les profondeurs et de tous les lointains. (22:27)
        Les rituels du Hajj sont exécutés sur une durée de cinq ou six jours, commençant officiellement le 8th Dhul-Hijjah pour se terminer le 13th du même mois ; Le Hajj est le plus grand événement religieux annuel qui se déroule dans l'Islam. Signifiant littéralement « prendre part au voyage », Les 7 étapes du Hajj sont les suivants:
      </p>
      <ul>
        <li><strong style={{ color: 'blue' }}>Étape 1 - Ihram et intentions</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 2 - Mina alias "Ville des tentes"</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 3 – Mina à Arafat, 9ème jour de Dhul-Hijjah</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 4 – Mouzdalifa</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 5 – Rami – Lapider le diable</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 6 – Nahr</strong></li>
        <li><strong style={{ color: 'blue' }}>Étape 7 - Adieu Tawaf</strong></li>
      </ul>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={hajjinfoImg3} alt="Description of the image" />
      </div>
      <p>
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Étape 1 - Ihram et intentions</h2>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Faire une intention pure et porter l'Ihram sont les deux premières étapes essentielles pour se rendre au Hajj. Après avoir fait le niyat, il est conseillé aux pèlerins musulmans de porter l'Ihram : deux morceaux de draps blancs non cousus pour les hommes et une Abaya ample pour les femmes, couvrant correctement tout le corps.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Il est recommandé au pèlerin de porter l'Ihram à Dhul-Hijjah avant d'entrer dans Miqat – les limites extérieures de La Mecque. Les cinq points d'entrée ou Miqats pertinents pour les pèlerins sont les suivants :
      </p>
      <ul style={{ paddingLeft: '20px' }}>
        <li><strong style={{ color: 'blue' }}>Abbyar Ali (Dhu'l Hulaifah)</strong> - C'est le point du Miqat pour les pèlerins venant de Médine ou d'Arabie Saoudite. Il leur est conseillé d'accomplir le Hajj al-Tamatt'u.</li>
        <li><strong style={{ color: 'blue' }}>(As-Sail Al-Kabeer) Qarn-al Manzil</strong> - C'est le point de Miqat pour les pèlerins venant de Taif ou Najd.</li>
        <li><strong style={{ color: 'blue' }}>Al-Juhfah</strong> – Situé près de Rabigh, c'est le point de Miqat pour les pèlerins venant d'Egypte, de Syrie ou du Maroc.</li>
        <li><strong style={{ color: 'blue' }}>Dhat'Irq</strong> - Est le point de Miqat pour les pèlerins venant de ou passant par l'Irak.</li>
        <li><strong style={{ color: 'blue' }}>Sa'adiyah (Yalamlam)</strong> - C'est le point du Miqat pour les pèlerins venant du Yémen, de l'Inde ou du Pakistan.</li>
      </ul>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; De plus, une fois à Ihram, il est conseillé aux pèlerins de réciter la Talbiyah en s'abstenant de tout acte pécheur. La talibyah doit être récitée à haute voix :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Labbayk Allaahumma labbayk, labbayk laa shareeka laka labbayk, 'innal-hamda, wanni'mata, laka walmulk, laa shareeka laka.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; "Toujours à ton service, ô Allah, tous à ton service. Toujours à votre service, vous n'avez aucun partenaire, jamais à votre service. En vérité, toutes les louanges, bénédictions et dominations sont à vous. Vous n'avez pas de partenaire. (Musulman 2:841)
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Après leur arrivée à La Mecque, les musulmans ayant l'intention de effectuer Hajj al-Tamatt'u devrait combiner les rituels de Omra avec Hajj. Pour cela, les musulmans font 7 fois le tour de la Sainte Kaaba dans le sens inverse des aiguilles d'une montre, également connu sous le nom de Tawaf. Ils accomplissent ensuite le rituel de Hajr-e-Aswad (pierre noire) et se dirigent vers la prochaine étape de la Omra, entre les collines Safa et Marwa, pour le Sa'i.
      </p>
      <p>
        <strong style={{ color: 'blue' }}>Marwa.</strong> Une fois la Omra terminée, les pèlerins commencent leur voyage vers Mina.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; Le port de l'ihram pendant le Hajj peut inciter les croyants à s'améliorer car il est similaire au linceul utilisé pour envelopper les corps avant qu'ils ne soient enterrés et que la mort survienne à l'improviste.
      </p>
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Étape 2 – Mina alias « City of Tents »</h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Situé à 5 à 6 km de La Mecque, Mina est une petite ville. En arrivant à la ville de tentes de Mina, il est conseillé aux pèlerins de s'y reposer jusqu'au lendemain. En commençant par la prière de midi (Zuhr) et en terminant par la prière de l'aube (Fajr), les pèlerins récitent les cinq Salahs pendant leur séjour à Mina.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={hajjinfoImg4} alt="Description of the image" />
      </div>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Aujourd'hui, le pays de Mina comprend des tentes modernes équipées de toutes les commodités essentielles. Les musulmans doivent réciter les prières obligatoires et non obligatoires pendant leur séjour à Mina.
      </p>
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Étape 3 – Mina à Arafat, 9e jour de Dhul-Hijjah</h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Le matin du deuxième jour du Hajj, soit 9th Dhul-Hijjah, les pèlerins commencent à marcher vers Arafat en récitant Talbiyah à pleine voix. Les pèlerins musulmans observent Zuhrain – une combinaison de prière Zuhr et Asr avec la prière Qasar en atteignant le mont Arafat.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; C’est ce qu’on appelle Waquf – l’acte de se tenir devant Allah (SWT) et est observé près du Jabal al-Rahmah de midi au coucher du soleil.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Situé à 14.4 km de Mina, le mont Miséricorde ou Jabal al Rahmah de l'Arafat était l'endroit où la bien-aimée Prophète Muhammad (SAW) prononcé le dernier sermon.
      </p>
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Étape 7 - Adieu Tawaf</h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Après avoir terminé le rituel, les pèlerins retournent à la Sainte Kaaba à La Mecque pour accomplir le "Tawaf al-Ifadah", également connu sous le nom de "Tawaf d'adieu", suivi de Sa'I. Bien que cela marque officiellement la fin du Hajj, de nombreux pèlerins visitent également Médine avant de rentrer chez eux.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={hajjinfoImg5} alt="Description of the image" />
      </div>
      <h3 style={{ color: 'brown', textAlign: 'center' }}>Pourquoi les musulmans font-ils le tour de la Kaaba 7 fois ?</h3>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Il n'y a aucune explication spécifique quant à la raison pour laquelle les musulmans font sept fois le tour de la Kaaba. Tout comme les musulmans sont invités à adorer cinq fois par jour, on leur dit de faire sept tours autour de la Sainte Kaaba lors de l'exécution de la Omra ou Hajj.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Cependant, l'acte de Tawaf reflète l'idée selon laquelle la vie d'un musulman devrait uniquement tourner autour de l'obéissance aux commandements d'Allah (SWT) et du respect de la Sunna du Prophète Muhammad (PSL).
      </p>
      <h2 style={{ color: 'brown', textAlign: 'center' }}>Résumé - 7 étapes du Hajj</h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; Le Hajj est l'un des cinq piliers essentiels de l'Islam. Étant obligatoire pour tous les musulmans, il est conseillé que le Hajj soit offert au moins une fois par des musulmans financièrement et physiquement capables.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  );
};

export default HajjInformation;
