import React, {useState} from 'react'
import '../style/sideBar.css';
import GestionOHPack from '../adminPages/GestionOHPack';
import GestionSimplePacks from '../adminPages/GestionSimplePacks';
import ReservationSimple from '../adminPages/ReservationsSimple';
import ReservationsOH from '../adminPages/ReservationsOH';
import Reservations from '../adminPages/Reservations';

import { Link, useNavigate, useLocation, us, useParams } from 'react-router-dom';
import { AddOmraHajj } from '../adminPages/AddOmraHajj';
import { AddSimple } from '../adminPages/AddSimple';
import { EditOmraHajj } from '../adminPages/EditOmraHajj';
import { EditSimple } from '../adminPages/EditSimple';

export const Sidebar = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id }= useParams();
   
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const Handlelogout = () => {
    localStorage.removeItem('admin');
    window.location.href= '/';

  }
  return (
    <div class="wrapper">
    <nav id="sidebar" className={isSidebarActive ? 'active' : ''}>
      <div class="sidebar-header">
        <h3>AMC Travel</h3>
      </div>

      <ul class="list-unstyled components">
       <p></p>
         <li class="active">
            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Gestion Packs</a>
            <ul class="collapse list-unstyled" id="homeSubmenu" >
                <li>
                    <a onClick={() => navigate('/simple-packs')}>Packs Simple</a>
                </li>
                <li>
                    <a onClick={() => navigate('/omra-hajj-packs')}>Packs Omra & Hajj</a>
                </li>
            </ul>
        </li> 
        <li>
            <a onClick={() => navigate('/simple-reservation')}>Réservation Simple</a>
        </li>
        <li>
            <a onClick={() => navigate('/omra-hajj-reservation')}>Réservation Omra & Hajj</a>
        </li>
        <li>
            <a style={{fontSize:'17px'}} onClick={() => navigate('/reservation')}>Réservation Personnalisée</a>
        </li>
      </ul>
    </nav>

<div id="content">
<nav class="navbar navbar2 navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">

        <button type="button" onClick={toggleSidebar} id="sidebarCollapse" class="btn btn-info">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>                  <span>Toggle</span>
        </button>
        <button type="button" onClick={Handlelogout} className='btn btn-danger'>
          Déconnexion
        </button>

    </div>
  </nav>
  
  {/* {location.pathname === '/' && <GestionOHPack />} */}
  {location.pathname === '/add-pack' && <AddOmraHajj />}
  {location.pathname.includes('/edit-oh-pack/') && id && <EditOmraHajj />}
  {location.pathname === '/add-simple-pack' && <AddSimple />}
  {location.pathname.includes('/edit-simple-pack/') && id && <EditSimple />}
  {location.pathname === '/simple-packs' && <GestionSimplePacks />}
  {location.pathname === '/omra-hajj-packs' && <GestionOHPack />}
  {location.pathname === '/simple-reservation' && <ReservationSimple />}
  {location.pathname === '/omra-hajj-reservation' && <ReservationsOH />}
  {location.pathname === '/reservation' && <Reservations />}
  {location.pathname === '/admin-panel' && <ReservationsOH />}

  </div>
  </div>
  )
}

export default Sidebar;

