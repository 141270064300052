import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../style/reservation.css';
import Select from 'react-select';
import Footer from '../components/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from 'react-intl-tel-input';
import Avatar from '../icons/Avatar.png';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import {api} from "../utils/constants";


export const ReservationHomeSimple = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [pack , setPack] = useState({});
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nationality, setNationality] = useState("");
  const [tele, setTele] = useState("");
  const [email, setEmail] = useState("");
  const [numPasseport, setNumPasseport] = useState("");
  const [expPasseport, setExpPasseport] = useState("");
  const [isSejourChecked, setIsSejourChecked] = useState(false);
  const [numCarte, setNumCarte] = useState("");
  const [expCarte, setExpCarte] = useState("");
  const [address, setAddress] = useState("");
  const [ville, setVille] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [cin, setCin] = useState(null);
  const [passeport, setPasseport] = useState(null);
  const [carteSejour, setCarteSejour] = useState(null);
  const [price, setPrice] = useState("");
  const [hasScrolled, setHasScrolled] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [countries, setCountries] = useState([]);

  
  useEffect(() => {
    axios
      .get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countriesInFrench = response.data.map(country => ({
          label: country.translations.fra.common, 
          value: country.translations.fra.common,
        }));
        setCountries(countriesInFrench);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  
  const handlePhoneNumberChange = (value, country, event, formattedValue) => {
    setTele(country.toString());
    setDialCode("+" + event.dialCode);
  };

  useEffect(() => {
    if (!hasScrolled) {
        window.scrollTo(0, 0);
        setHasScrolled(true);
      }    
    const apiUrl = `simplepacks/${id}/`;
    api.get(apiUrl)
         .then((response) => {
            setPack(response.data);
         })
  }, [hasScrolled, id])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    // Create a FormData object and append all the values
    const formData = new FormData();
    formData.append('id_pack', id);
    formData.append('nom', nom);
    formData.append('prenom', prenom);
    formData.append('nationality', nationality.value);
    formData.append('tele', dialCode +" "+ tele);
    formData.append('email', email);
    formData.append('numPasseport', numPasseport);
    formData.append('expPasseport', expPasseport);
    formData.append('isSejourChecked', isSejourChecked);
    formData.append('numCarte', numCarte);
    formData.append('expCarte', expCarte);
    formData.append('address', address);
    formData.append('ville', ville);
    formData.append('codePostal', codePostal);

    if (cin) {
      formData.append('cin', cin);
    }
    if (passeport) {
      formData.append('passeport', passeport);
    }
    if (carteSejour) {
      formData.append('carteSejour', carteSejour);
    }

    try {
      const response = await api.post('simplereservations/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        navigate('/home');

        setNom("");
        setPrenom("");
        setAddress("");
        setCarteSejour(null);
        setCodePostal("")
        setCin(null);
        setEmail("");
        setExpCarte("");
        setExpPasseport("");
        setNationality("");
        setNumCarte("");
        setNumPasseport("");
        setTele("");
        setVille("");
        setIsSejourChecked(false);
        
        toast.success('Votre réservation a bien été faite !', {
            position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
         

      } else {
        setLoading(false);
        console.error('Failed to submit reservation');
        setErrorMessage(
          "Une erreur s'est produite lors de la soumission de la réservation. Veuillez réessayer."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
      setErrorMessage(
        "Une erreur s'est produite lors de la soumission de la réservation. Veuillez réessayer."
      );
    }
  };

  return (
    <>
    <FloatingWhatsApp
        phoneNumber="+33744192665" 
        accountName="AMC Travels" 
        avatar={Avatar}
        statusMessage="Vous recevrez une réponse dans moins de 24 heures."
        chatMessage="Cher client, veuillez écrire le message que vous souhaitez nous envoyer via WhatsApp." // Replace with your custom message
        styles={{
          left: '10px',
          bottom: '10px',
          zIndex: 100,
        }}
      />
        <ToastContainer/>
        <div className='nav-cont'>
            <Navbar/>
        </div>
        <h2 className='text-center mt-4 mb-2'>Reservation {pack.label}</h2>
        <div className="container reservation-container mt-4 mb-5">
            <form onSubmit={handleSubmit}>
                <h5 className='mb-3'>Veuillez remplir le formulaire suivant :</h5>
                <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="nom">Nom</label>
                    <input type="text" class="form-control" value={nom} onChange={(e) => setNom(e.target.value)} id="nom" placeholder="Nom" required />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="prenom">Prénom</label>
                    <input type="text" class="form-control" value={prenom} onChange={(e) => setPrenom(e.target.value)} id="prenom" placeholder="Prénom" required />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="mail">Email</label>
                    <input type="mail" class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="mail" placeholder="exemple@exemple.com" />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div class="form-group w-100">
                            <label for="">Nationalité</label>
                            <Select   options={countries} value={nationality} onChange={(e) => setNationality(e)} search={true} name="countries" placeholder="Nationalité" required/>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="tele">Téléphone</label>
                    <br/>
                    <PhoneInput
        containerClassName="intl-tel-input"
  inputClassName="form-control"
        value={tele}
        onPhoneNumberChange={handlePhoneNumberChange}
        preferredCountries={['FR', 'GB', 'CA']} // Set preferred countries (optional)
        required/>                </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="numPasseport">Numéro du passeport</label>
                    <input type="text" class="form-control" value={numPasseport} onChange={(e) => setNumPasseport(e.target.value)} id="numPasseport" placeholder="" />
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="expPasseport">Date d'expiration du passeport</label>
                    <input type="date" class="form-control" value={expPasseport} onChange={(e) => setExpPasseport(e.target.value)} id="expPasseport" />
                  </div>
                </div>
                </div>

                <div class="form-check mt-3 mb-3">
                    <input class="form-check-input " checked={isSejourChecked} onChange={(e) => setIsSejourChecked(e.target.checked)} type="checkbox" id="Sejour"/>
                    <label class="form-check-label" style={{fontSize: '18px', marginTop: '2px'}} for="Sejour">
                        Cocher si vous disposer d'une carte de séjour
                    </label>
                </div>
                {
                    isSejourChecked && (
                        <div className='row mt-4'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="numSejour">Numéro de Carte</label>
                    <input type="text" class="form-control" value={numCarte} onChange={(e) => setNumCarte(e.target.value)} id="numSejour" placeholder="" />
                </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="expSejour">Date d'expiration du Carte</label>
                    <input type="date" class="form-control" value={expCarte} onChange={(e) => setExpCarte(e.target.value)} id="expSejour" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="carteSejour">Carte de séjour (Document)</label>
                    <input type="file" class="form-control" onChange={(e) => setCarteSejour(e.target.files[0])} id="carteSejour" />
                  </div>
                </div>
                </div>
                    )
                }
                <hr/>
                <h5 className='mt-3 mb-3'>COORDONNÉES :</h5>
                <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="address">Adresse postale</label>
                    <input type="text" class="form-control" value={address} onChange={(e) => setAddress(e.target.value)} id="address" placeholder="" />
                </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="ville">Ville</label>
                    <input type="text" class="form-control" value={ville} onChange={(e) => setVille(e.target.value)} id="ville" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="codePostal">Code postale</label>
                    <input type="text" class="form-control" value={codePostal} onChange={(e) => setCodePostal(e.target.value)} id="codePostal" placeholder="" />
                  </div>
                </div>
                </div>
                <hr/>
                <h5 className='mt-3 mb-3'>DOCUMENTS :</h5>
                <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="cin">CIN</label>
                    <input type="file" class="form-control" onChange={(e) => setCin(e.target.files[0])} id="cin" />
                </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div class="form-group">
                    <label for="passeport">Passeport</label>
                    <input type="file" class="form-control" onChange={(e) => setPasseport(e.target.files[0])} id="passeport" />
                </div>
                </div>
                </div>
                <p className="mt-4 mb-2"><b>Note :</b>Une fois que vous confirmez votre réservation, nous vous contacterons via WhatsApp ou par e-mail afin de valider votre demande.</p>
                <div className="row">
            <div className="col w-100">
              <button
                type="submit"
                className="btn w-100 btn-primary"
                disabled={loading}
              >
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                Confirmer la reservation
              </button>
            </div>
          </div>


          <div className="row">
              <div className="col w-100">
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
            </div>
            </form>
        </div>
        <Footer/>
    </>
  )
}

export default ReservationHomeSimple;
