import React from "react";
import omrainfoImg2 from "../images/omrainfo/omrainfo2.jpeg";
import omrainfoImg3 from "../images/omrainfo/omrainfo3.jpeg";
import omrainfoImg4 from "../images/omrainfo/omrainfo4.jpeg";
import omrainfoImg5 from "../images/omrainfo/omrainfo5.jpeg";
import omrainfoImg6 from "../images/omrainfo/omrainfo6.jpeg";
import omrainfoImg7 from "../images/omrainfo/omrainfo7.jpeg";
import omrainfoImg8 from "../images/omrainfo/omrainfo8.jpeg";

const OmraInformation = () => {
  return (
    <div className="container omra-info">
      <h2 style={{ textAlign: "center", color: "brown" }}>
        Guide complet de la Omra pour les pèlerins
      </h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;<strong>Etape 1 - Le Rite de la Omra</strong>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={omrainfoImg2} alt="Description of the image" />
        </div>
        Avant de se mettre en Ihram (sacralisation), il est bon de se laver
        entièrement le corps en faisant un Ghusl (bain rituel), de s’épiler
        certaines parties comme les aisselles et le pubis, se couper les ongles,
        se tailler la moustache et de se mettre du parfum sur le corps et non
        pas sur l’habit d’Ihram (musc par exemple). Après avoir effectué le
        Ghusl (bain rituel), le pèlerin attache autour de sa taille le Izar (une
        sorte de pagne blanc) et met sur le haut de son corps le Rida (drap
        blanc). Pour se chausser, le haj (pèlerin) mettra des sandales. Il est
        possible d’avoir sur soi l’habit d’Ihram dans sa chambre d’hôtel avant
        d’être au Miqat (lieu de sacralisation), comme cela a été fait par le
        Prophète (Paix et Bénédictions soient sur lui) et les Sahaba. Il faut
        bien avoir à l’esprit que le fait de porter l’habit d’Ihram n’est pas
        synonyme d’entrée en état de sacralisation Après cela, le haj (pèlerin)
        monte dans le bus pour se rendre à La Mecque en passant par le Miqat.
        Dans notre cas, comme nous venons de Médine, notre Miqat sera celui de
        Dhou-El Houlayfa que l’on appelle aussi Abyar-Ali (distant de Médine de
        12 Km). Une fois au Miqat, les pèlerins pour qui le Miqat est Dhou El
        Houlayfa peuvent (acte conseillé mais pas obligatoire) effectuer une
        prière surérogatoire car l’endroit est béni. Dans le cas où le pèlerin
        arrive au Miqat au moment de la prière de Dohr, il effectue la prière de
        Dohr, puis il fait la formulation de l’intention (comme l’avait fait le
        Messager d’ALLAH, Paix et Bénédictions soient sur lui). Dès qu’il finit
        sa prière, et qu’il décide d’entrer en état d’Irham (sacralisation), le
        haj (pèlerin) se tient en direction de la Qibla (en position debout)
        pour faire l’intention qui suit :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« LABAYKA ALLAHOUMMA ‘OMRA »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« Mon Seigneur, j’accours à Ton appel pour
        accomplir une ‘Omra »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;auquel on peut ajouter
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« ALLAHOUMMA HADHIHI ‘OMRATAN LA RIYA-A FIHA WA
        LA SOUM’ATAN »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;et « sans ostentation ni recherche de réputation
        »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;On peut, dans le cas où l’on craint qu’un
        évènement nous empêcherait de terminer la Omra ou le Hajj, rajouter à la
        phrase précédente ce qui suit :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« ALLAHOUMMA MAHILLI HAYTHOU HABASTANI »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« Mon Seigneur, je me désacraliserai là où Tu me
        feras arrêter »la
      </p>
      <h3 style={{ textAlign: "center", color: "brown" }}>
        Etape 2 - la Talbiya et l’Ihram
      </h3>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Dès que le pèlerin est dans son bus, il commence
        à faire la Talbiya (à haute voix) :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« LABAYKA ALLAHOUMMA LABAYK, LABAYKA LA CHARIKA
        LAKA LABAYK, INNA AL-HAMDA, WA NI’MATA, LAKA WAL-MOULK, LA CHARIKA LAK »
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;«Je réponds à Ton appel ô Allah ! Je réponds à
        Ton appel. Tu n’as pas d’associé. La Louange et Le Bienfait
        t’appartiennent ; ainsi que La Royauté, Tu n’as pas d’associé ».
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Le pèlerin doit prononcer la Talbiya tout au
        long de sa route. Il peut ajouter de temps en temps le Tahlil :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;«LA ILAHA ILA ALLAH»
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;«Nulle divinité à part ALLAH».
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Il faut garder à l’esprit qu’en état de
        sacralisation (Ihram), certaines choses deviennent interdites comme :
      </p>
      <ul>
        <li>le fait de se raser ou de se couper les cheveux,</li>
        <li>
          le fait de se couper les ongles (que ce soit des mains ou des pieds),
        </li>
        <li>le fait de s’embaumer le corps ou le vêtement de parfum,</li>
        <li>
          le fait de conclure un zawaj (mariage) ou en faire la demande pour soi
          ou pour quelqu’un d’autre,
        </li>
        <li>
          le fait de s’adonner aux préliminaires de la relation sexuelle, tels
          qu’embrasser son épouse…,
        </li>
        <li>le fait de faire l’acte charnel concrètement avec son épouse,</li>
        <li>le fait d’abattre un gibier.</li>
      </ul>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Ces 7 prohibitions concernent à la fois les
        hommes et les femmes. Toutefois, deux autres prohibitions s’ajoutent aux
        hommes et sont exclusives à ces derniers.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Ce sont donc :
      </p>
      <ul>
        <li>
          le fait de se mettre quelque chose (qui soit en contact directe) sur
          la tête comme un chèche, un chapeau, une écharpe, une casquette, une
          visière…,
        </li>
        <li>
          le fait de mettre des habits où il y a des coutures et qui sont
          façonnés comme par exemple des pantalons, des chemises, des
          survêtements, des pulls, des tee-shirts…
        </li>
      </ul>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Les sœurs, quant à elles, peuvent mettre les
        vêtements qu’elles désirent ; toutefois, il ne faut pas qu’elles mettent
        des gants ni cacher leur visage par un sitar par exemple.
      </p>
      <h2 style={{ textAlign: "center", color: "brown" }}>
        Étape 3 - Arrivée à la Mecque
      </h2>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Quand le pèlerin arrive à l’endroit béni de La
        Mecque (AL HARAMOU AL MAKKI), et que les maisons de La Mecque soient
        aperçues, le Haj (pèlerin) arrête de prononcer la Talbiya pour se
        consacrer à faire certains actes, qui sont :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Il est bon pour le pèlerin (haj) de se laver
        entièrement le corps en faisant le « Ghusl » (bain rituel, durant lequel
        l’ensemble du corps doit être atteint par l’eau en utilisant des
        produits d’hygiène non parfumés) et d’entrer dans la ville sacrée de La
        Mecque en plein jour (si c’est possible par la permission d’ALLAH).
      </p>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={omrainfoImg3} alt="Description of the image" />
      </div>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Une fois que le pèlerin pose ses affaires dans
        sa chambre d’hôtel, il se dirige vers le Masjid el Haram (ce qui
        signifie en arabe : Mosquée Sacrée) en y entrant par la porte BANOU
        SHAYBA, en avançant le pied droit et en proclamant la phrase suivante :
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;«ALLAHOUMMA SALI ‘ALA MOHAMADIN WA SALLIM,
        ALLAHOUMMA IFTAH LI ABWÂBA RAHMATIK»
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;« que la Paix et le Salut d’Allah soient sur
        Mouhammed, ô Allah ouvre-moi les portes de Ta Miséricorde ».
      </p>

      <p>
        Une fois que le pèlerin aperçoit la Sainte Ka’ba, il peut lever ses
        mains pour invoquer le Seigneur de l’Univers et faire des dou’as,
        c’est-à-dire des invocations (l’éminent IBN ABBAS, qu’ALLAH soit
        satisfait de lui, l’avait fait). Il n’y a pas de dou’a en particulier,
        toutefois il est dit que le Prince des Croyants OMAR (qu’ALLAH soit
        satisfait de lui) invoquait comme suit (version phonétique) :
        <br />
        «ALLAHOUMMA ANTA SALAM, WA MINKA SALAM, FA HAYINA RABANA BI SALAM»
      </p>
      <h2 style={{ textAlign: "center", color: "brown" }}>
        Étape 4 - le Tawaf d’arrivée (tawafou El-Qoudoum)
      </h2>
      <p>
        Ensuite, le pèlerin va vers la pierre noire (= AL-Hajarou El-Aswad), se
        tient en face d’elle et dit :
        <br />
        « BISMILLAH, ALLAHOU AKBAR »
        <br />
        Ensuite, il la touche et l’embrasse.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={omrainfoImg4} alt="Description of the image" />
      </div>
      <p>
        Dans le cas où il ne peut l’embrasser de manière effective, il la touche
        avec sa main droite et embrasse ensuite sa main. Dans le cas où il ne
        peut même pas la toucher, le pèlerin lui fera signe avec sa main de
        loin. Le pèlerin répète cela à chaque tour.
      </p>
      <p>
        Il faut savoir qu’il n’est pas autorisé de pousser les autres pèlerins
        pour s’approcher et tenter d’embrasser AL-Hajar El-Aswad (=la pierre
        noire).
      </p>
      <p>
        Avant de débuter Le Tawaaf El Qoudoum (tawaf d’arrivée), le pèlerin doit
        laisser nue son épaule droite en passant son Rida (=drap ou houppelande)
        en dessous de son aisselle droite puis en la mettant sur son épaule
        gauche. Ceci est appelé EL-IDTEBA’.
      </p>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={omrainfoImg5} alt="Description of the image" />
      </div>

      <p>
        A partir de ce moment-là, le pèlerin entame son TAWAF en faisant des
        tours autour de la Ka’ba de sorte que la Ka’ba soit à sa gauche en
        passant derrière EL-HIDJR. Le pèlerin est tenu de faire sept tours
        (CHAWT), à chaque passage devant la pierre noire il sera comptabilisé un
        CHAWT (un tour). Le pèlerin doit faire les trois premiers tours tout en
        hâtant le pas (AL-RAML) : ceci ne se fait que dans TAWAAF EL-QOUDOUM),
        puis il marche pour les 4 derniers.
      </p>
      <p>
        Il est bon de toucher avec sa main EL-ROUKN EL YAMANI (nom du coin
        précédant le coin de la pierre noire) à chaque passage (pas de baiser
        dans cette situation-là). Si cela est impossible, il n’est pas
        nécessaire d’effectuer un signe avec sa main.
      </p>
      <p>
        Il est bien de réciter durant la distance qui sépare EL-ROUKN EL YAMANI
        et la pierre noire :
        <br />
        «RABANA ATINA FI DOUNYA HASSANAH, WA FI-L-AKHIRATIHASSANAH, WA QINA
        ‘ADHABA N-NAR»
        <br />
        Ce qui signifie «ô Seigneur ! Accorde nous le bien ici-bas et dans
        l’au-delà ; et protège-nous du châtiment du feu»
      </p>
      <p>
        Il faut savoir que, mise à part la dou’a citée juste avant, le Tawaf ne
        contient pas de dou’as précises comme le pensent certains. Le pèlerin
        peut donc dire la dou’a qu’il veut ou réciter du Saint Coran.
      </p>
      <p>
        Il est possible d’aller à un endroit nommé le Moultazam (lieu entre la
        pierre noire et la porte de la Ka’ba) pour formuler des dou ‘as en
        mettant le visage, la poitrine, les mains et les avant-bras sur cet
        endroit.
      </p>
      <p>Quand le 7ème tour est fini, le pèlerin couvre son épaule droite.</p>
      <p>
        Ensuite, il va vers MAQAM IBRAHIM c‘est-à-dire la station d’Ibrahim, et
        récite le verset qui suit :
        <br />
        «WA TAKHIDHOU MIN MAQAMI IBRAHIMA MOUSSALLA »
        <br />
        (= sens approché ) : « Adoptez pour lieu de prière, ce lieu où Ibrahim
        se tint debout ».
      </p>


      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={omrainfoImg6} alt="Description of the image" />
      </div>



      <p style={{paddingLeft: '20px'}}>
  &nbsp;&nbsp;&nbsp;&nbsp; Par la suite, le pèlerin se place derrière la station d’Ibrahim de façon à ce que le Maqam Ibrahim soit entre le pèlerin et la Ka’ba, et il fait 2 unités de prière (rak’a) avec sourate EL KAFIROUN (n°109) dans la 1ère rak’a et sourate AL IKHLAS (n°112) dans la deuxième rak’a, avec la permission d’ALLAH. Le pèlerin veillera à ne pas passer devant les pèlerins en prière, et de ne pas laisser les autres passer devant lui.
  <br />
  &nbsp;&nbsp;&nbsp;&nbsp; Après cette prière, le puits de ZAM ZAM n’étant plus accessible, il se dirige vers les points d’eau et boit de l’eau de Zamzam jusqu’à être rassasié et il en met aussi sur sa tête. Ensuite, il va à AL-Hajar El-Aswad (= pierre noire) et prononce le takbir «ALLAHOU AKBAR» et l’embrasse comme vu plus haut.
</p>
<h2 style={{ color: 'brown', textAlign: 'center', paddingLeft: '20px' }}>Etape 5 - Le Sa’i entre Safa et Marwa</h2>
<div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={omrainfoImg7} alt="Description of the image" />
      </div>
      <p style={{ paddingLeft: '20px' }}>
  &nbsp;&nbsp;&nbsp;&nbsp; Après cela, le pèlerin se dirige en direction de Safa. Dès qu’il se trouve à proximité de Safa, il récite la ayah (verset du Coran) qui suit :<br/>
  «INNA SAFA WA-L-MARWATA MIN CHA’AIRI ALLAHI, FAMAN HAJJA-l-BAYTA AWI ‘TAMARA FALA JOUNAHA ‘ALEYHI AN YATAWAFA BIHIMA, WA MAN TATAWA’A KHAYRAN FA INNA ALLAHA CHAKIROUN ‘ALIM».<br/>
  Sens approché «SAFA et MARWA sont vraiment parmi les lieux sacrés d’ALLAH. Quiconque fait le pèlerinage à la Maison (Sacrée) ou fait l’OMRA ne commet pas de péché en faisant le va-et-vient entre ces deux monts. Et quiconque fait de son propre gré une bonne œuvre, alors ALLAH est Reconnaissant, Omniscient». <br/>
  Le haj (pèlerin) dit ensuite : «NABDAOU BIMA BADAA ALLAHOU BIHI». Sens approché : « je débute par ce qu’ ALLAH a cité en premier lieu ». <br/>
  Ensuite, le pèlerin débute par le mont SAFA : ainsi il le gravit de sorte qu’il voit la KA’BA, il va dans sa direction et prononce la formule :<br/>
  «ALLAHOU AKBAR, ALLLAHOU AKBAR, ALLAHOU AKBAR. LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAHOU, LAHOU AL-MOULK, WA LAHOU-L-HAMD, YOUHYI WAYOUMIT, WA HOUWA ‘ALA KOULLI CHAY’IN QADIR, LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAH, ANJAZA WA’DAH, WA NASSARA ‘ABDAH, WA HAZAMA-L-AHZABA WAHDAH». <br/>
  Sens approché : « Allah est le Plus Grand, Allah est le Plus Grand, Allah est le Plus Grand. Il n’y a pas dedieu à part Allah, Seul, sans associé, à Lui appartient la Royauté et à Lui la louange, Il donne la vie et donne la mort, et Il est Capable de toutes choses. Il n’y a pas de dieu à part Allah, Seul, sans associé. Il a réalisé Sa promesse, accordé la victoire à Son serviteur et a vaincu toutes les factions Seul. » <br/>
  Puis, le pèlerin lève ses mains et effectue les dou’as qu’il désire avec la permission d’ALLAH. Il répète ceci 3 fois d’affilée en prononçant à nouveau la formule vue précédemment et en invoquant des dou’as entre deux. <br/>
  Après cela, le pèlerin descend du mont de Safa pour se rendre au mont de Marwa (en marchant) en effectuant des dou’as le long de ce trajet. Dès que le pèlerin atteint le premier repère (une lumière de couleur verte), il doit hâter le pas pour arriver au second repère (une lumière verte également). A ce moment-là (dès qu’il arrive au 2nd repère), le pèlerin peut remarcher normalement jusqu’à atteindre le mont de Marwa. <br/>
  Le haj (pèlerin) le gravit et effectue la même chose qu’au moment où il était à Safa en allant dans la direction de la Ka’ba et en disant, comme précédemment, la formule :
</p>


<h2 style={{ color: 'brown', textAlign: 'center', paddingLeft: '20px' }}>Etape 5 - Le Sa’i entre Safa et Marwa</h2>
<p style={{ paddingLeft: '20px' }}>
  &nbsp;&nbsp;&nbsp;&nbsp; Après cela, le pèlerin se dirige en direction de Safa. Dès qu’il se trouve à proximité de Safa, il récite la ayah (verset du Coran) qui suit :<br/>
  «INNA SAFA WA-L-MARWATA MIN CHA’AIRI ALLAHI, FAMAN HAJJA-l-BAYTA AWI ‘TAMARA FALA JOUNAHA ‘ALEYHI AN YATAWAFA BIHIMA, WA MAN TATAWA’A KHAYRAN FA INNA ALLAHA CHAKIROUN ‘ALIM».<br/>
  Sens approché «SAFA et MARWA sont vraiment parmi les lieux sacrés d’ALLAH. Quiconque fait le pèlerinage à la Maison (Sacrée) ou fait l’OMRA ne commet pas de péché en faisant le va-et-vient entre ces deux monts. Et quiconque fait de son propre gré une bonne œuvre, alors ALLAH est Reconnaissant, Omniscient». <br/>
  Le haj (pèlerin) dit ensuite : «NABDAOU BIMA BADAA ALLAHOU BIHI». Sens approché : « je débute par ce qu’ ALLAH a cité en premier lieu ». <br/>
  Ensuite, le pèlerin débute par le mont SAFA : ainsi il le gravit de sorte qu’il voit la KA’BA, il va dans sa direction et prononce la formule :<br/>
  «ALLAHOU AKBAR, ALLLAHOU AKBAR, ALLAHOU AKBAR. LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAHOU, LAHOU AL-MOULK, WA LAHOU-L-HAMD, YOUHYI WAYOUMIT, WA HOUWA ‘ALA KOULLI CHAY’IN QADIR, LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAH, ANJAZA WA’DAH, WA NASSARA ‘ABDAH, WA HAZAMA-L-AHZABA WAHDAH». <br/>
  Sens approché : « Allah est le Plus Grand, Allah est le Plus Grand, Allah est le Plus Grand. Il n’y a pas dedieu à part Allah, Seul, sans associé, à Lui appartient la Royauté et à Lui la louange, Il donne la vie et donne la mort, et Il est Capable de toutes choses. Il n’y a pas de dieu à part Allah, Seul, sans associé. Il a réalisé Sa promesse, accordé la victoire à Son serviteur et a vaincu toutes les factions Seul. » <br/>
  Puis, le pèlerin lève ses mains et effectue les dou’as qu’il désire avec la permission d’ALLAH. Il répète ceci 3 fois d’affilée en prononçant à nouveau la formule vue précédemment et en invoquant des dou’as entre deux. <br/>
  Après cela, le pèlerin descend du mont de Safa pour se rendre au mont de Marwa (en marchant) en effectuant des dou’as le long de ce trajet. Dès que le pèlerin atteint le premier repère (une lumière de couleur verte), il doit hâter le pas pour arriver au second repère (une lumière verte également). A ce moment-là (dès qu’il arrive au 2nd repère), le pèlerin peut remarcher normalement jusqu’à atteindre le mont de Marwa. <br/>
  Le haj (pèlerin) le gravit et effectue la même chose qu’au moment où il était à Safa en allant dans la direction de la Ka’ba et en disant, comme précédemment, la formule :<br/><br/>
  « ALLAHOU AKBAR, ALLLAHOU AKBAR, ALLAHOU AKBAR. LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAHOU, LAHOU AL-MOULK, WA LAHOU-L-HAMD, YOUHYI WAYOUMIT, WA HOUWA ‘ALA KOULLI CHAY’IN QADIR, LA ILAHA ILLA-LLAHOU WAHDAHOU LA CHARIKA LAH, ANJAZA WA’DAH, WA NASSARA ‘ABDAH, WA HAZAMA-L-AHZABA WAHDAH».<br/>
  Le pèlerin fera aussi des dou’as entre deux. Le premier trajet (Chawt) est alors accompli.<br/><br/>
  Ensuite, il redescend du mont Marwa pour se rendre au mont Safa en hâtant sa marche entre les deux lumières vertes (les repères). Lorsqu’il est sur le mont de Safa, le haj (pèlerin) réitère les actions faites lors de la 1ère fois : il accomplit ainsi le 2nd trajet (Chawt).<br/><br/>
  Le pèlerin fera, avec l’aide d’ALLAH, 7 trajets (Chawt). L’ultime trajet se clôturera sur le mont de Marwa.<br/><br/>
  Il faut savoir que la Ayah (verset) « INNA S-SAFA WA-L-MARWATA… » ne se lit qu’au moment où le pèlerin se rapproche du mont Safa pour la 1ère fois avant d’entamer le SA’I. Il ne faut pas non plus la relire à chaque passage par le mont Safa ni Marwa. Idem pour la parole : « ABDAOU BIMA…». <br/><br/>
  Le Sa’i n’a pas de dou’a en particulier : le pèlerin peut donc dire la dou’a qu’il veut incha ALLAH. Il est rapporté tout de même une dou’a que disaient les compagnons :<br/>
  « ALLAHOUMA-GHFIR WA RHAM, INNAKA ANTA-L-A’ZOU-L-AKRAM »<br/><br/>

  <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={omrainfoImg8} alt="Description of the image" />
      </div>


  Quand le haj (pèlerin) clôture les 7 trajets (Chawt), il se coupe un peu les cheveux. Si le temps entre la Omra et le Hadj peut laisser le temps aux cheveux de repousser, alors le pèlerin les rasera incha ALLAH.<br/><br/>
  Le pèlerin vient de terminer sa Omra. Il se désacralise donc par la permission d’ALLAH.
</p>


    </div>
  );
};

export default OmraInformation;
