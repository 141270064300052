import React from "react";

const TestimonialSlider = ({ testimonials }) => {
  // Chunk the testimonials array into arrays of 3 items each
  const chunkedTestimonials = testimonials.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 3);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  // Function to generate star icons based on the number of stars
  const generateStars = (numStars) => {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      stars.push(
        <li key={i}>
          <i className="fa fa-star"></i>
        </li>
      );
    }
    return stars;
  };

  return (
    <section className="pt-5 pb-5">
      <h2
        className="w-100 text-center mt-5 title-blue text-black"
        id="nos-sponsor"
        style={{ padding: "20px" }}
      >
        Témoignages
      </h2>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              id="carouselExampleIndicators2"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {chunkedTestimonials.map((chunk, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div className="row justify-content-center">
                      {chunk.map((testimonial, idx) => (
                        <div key={idx} className="col-md-4 mb-3">
                          <div className="card bg-orange text-black" style={{ borderRadius: "20px" }}>
                            <div className="card-body d-flex flex-column justify-content-center" style={{ height: "400px" }}>
                              <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                                {generateStars(testimonial.numberOfStars)}
                              </ul>
                              
                            
                              <h4 className="card-title text-center">
                                {testimonial.title}
                              </h4>
                              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <p className="card-text text-center">
                                  {testimonial.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div>
            <a
              className="btn btn-orange mb-3 mr-1"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-arrow-left"></i>
            </a>
            <a
              className="btn btn-orange mb-3"
              href="#carouselExampleIndicators2"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
