import React from "react";
import Guide from "../icons/guide.png";
import Dej from "../icons/petitDej.png";
import Plane from "../icons/plane.png";
import Transport from "../icons/transport.png";
import Visa from "../icons/visa.png";
import Medina from "../icons/medina.png";
import Makkah from "../icons/makkah.png";
import Company from "../icons/company.png";

const PackCarouselItem = ({ pack, navigate }) => {
  const moisFrancais = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];

  return (
    <div className="mt-4 d-flex justify-content-center">
      <div className="packCarousel">
      <div style={{ position: 'relative', display: 'inline-block' }}>
          <img className="bg-img" src={pack.image} alt="" />
          <span style={{ position: 'absolute', top: '0', right: '0'}} className="badge badge-light p-2 badge-element">
            {(() => {  
              let dateAller = new Date(pack.date_aller);
              let dateRetour = new Date(pack.date_retour);      
              let difference = dateRetour - dateAller;
              let days = Math.ceil(difference / (1000 * 60 * 60 * 24));
              return `${days} nuits`;
            })()}
          </span>
        </div>
        <h4 className="text-left mt-2">
          <b>{pack.label}</b>
        </h4>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Plane} />
          {(() => {
            let dateAller = new Date(pack.date_aller);
            let dateRetour = new Date(pack.date_retour);
            if (dateAller.getMonth() === dateRetour.getMonth())
              return `du ${dateAller.getDate()} au ${dateRetour.getDate()} ${
                moisFrancais[dateAller.getMonth()]
              }. ${dateAller.getFullYear()}`;
            else if (dateAller.getFullYear() === dateRetour.getFullYear())
              return `du ${dateAller.getDate()} ${
                moisFrancais[dateAller.getMonth()]
              }. au ${dateRetour.getDate()} ${
                moisFrancais[dateRetour.getMonth()]
              }. ${dateAller.getFullYear()}`;
            else
              return `du ${dateAller.getDate()} ${
                moisFrancais[dateAller.getMonth()]
              }. ${dateAller.getFullYear()} au ${dateRetour.getDate()} ${
                moisFrancais[dateRetour.getMonth()]
              }. ${dateRetour.getFullYear()}`;
          })()}
        </p>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Medina} />
          Médine: {pack.hotel_medina}
        </p>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Makkah} />
          Makkah :{pack.hotel_makkah}
        </p>
        {pack.petit_dejeuner && (
          <p className="packs-para">
            <img className="icon" alt="img not loaded" src={Dej} />
            Petit déjeûner inclus
          </p>
        )}
        {pack.dejeuner && (
          <p className="packs-para">
            <img className="icon" alt="img not loaded" src={Dej} />
            Déjeûner inclus
          </p>
        )}
        {pack.dinner && (
          <p className="packs-para">
            <img className="icon" alt="img not loaded" src={Dej} />
            Dinner inclus
          </p>
        )}
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Guide} />
          Guide Omra et visites incluses
        </p>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Transport} />
          Transfert Hôtels inclus
        </p>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Visa} />
          Visa inclus
        </p>
        <p className="packs-para">
          <img className="icon" alt="img not loaded" src={Company} />
          Vols {pack.aller_company}
        </p>
        {/* Price information */}
    <div className="row mt-3">
      <div className="col text-right">
        <h5 className="text-right test">
          à partir de
          <br />
          <h3>
            {Math.floor(pack.quad_price)}€
            <span className="helper">/Pers</span>
          </h3>
        </h5>
      </div>
    </div>

       {/* Buttons */}
    <div className="row mt-3">
      <div className="col-6">
        <button
          className="btn btn-orange w-100"
          onClick={() => navigate(`/reservation-omra-hajj/${pack.id}`)}
        >
          Réserver
        </button>
      </div>
      <div className="col-6">
        {pack.type === "omra" && (
          <button
            className="btn btn-info w-100"
            onClick={() => navigate(`/details/${pack.id}`)}
          >
            Plus d'info
          </button>
        )}
        {pack.type === "hajj" && (
          <button
            className="btn btn-info w-100"
            onClick={() => navigate(`/details-hajj/${pack.id}`)}
          >
            Plus d'info
          </button>
        )}
      </div>
    </div>
      </div>
    </div>
  );
};

export default PackCarouselItem;
