import React from "react";
import "../style/Navbar.css";
import Logo from "../icons/Logo.png";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    // Navigate to the home page
    navigate("/home");

    // Scroll to the desired section after a short delay to ensure the page is loaded
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-transparent">
      <div className="container">
        <a
          className="navbar-brand title"
          onClick={() => (window.location.href = "/")}
        >
          <img
            className="logo mr-2 text-white"
            src={require("../icons/Logo.png")}
          />
        </a>
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ color: "blue" }}
        >
          <span className="navbar-toggler-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>{" "}
          </span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item text-center">
              <a
                className="nav-link"
                onClick={() => (window.location.href = "/")}
              >
                Accueil
              </a>
            </li>
            <li className="nav-item text-center">
              <a className="nav-link"  onClick={() => scrollToSection('packsimple')}>
                PACKS SIMPLES
              </a>
            </li>

            <li className="nav-item text-center">
              <Link to="/omra" className="nav-link">
                OMRA
              </Link>
            </li>
            

            <li className="nav-item text-center">
              <Link to="/hajj" className="nav-link">
                Hajj
              </Link>{" "}
              {/* Updated Link */}
            </li>
            <li className="nav-item text-center">
              <a
                className="nav-link"
                rel="noreferrer"
                href="https://locations.amctravel.net/"
                target="_blank"
              >
                Location Auto
              </a>
            </li>
            {/* <li className="nav-item text-center">
              <a className="nav-link" href="#nos-sponsor">
              Nos Sponsors
              </a>
            </li>
             <li className="nav-item text-center">
              <a className="nav-link" href="#about-us">
                 About Us
              </a>
            </li> */}
            <li className="nav-item text-center">
              <a className="nav-link" href="#contact-us">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
