import React, { useEffect, useState } from "react";

const Sponsors = () => {
  const [imagesPerSlide, setImagesPerSlide] = useState(5); // Default to 5 images per slide
  const totalImages = 10; // Total number of images

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setImagesPerSlide(1); // For mobile devices
      } else {
        setImagesPerSlide(5); // For medium and large sizes
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h2
        className="w-100 text-center mt-5 title-blue"
        id="nos-sponsor"
        style={{ padding: "20px" }}
      >
        Hôtels partenaires
      </h2>
      <div
        id="carouselExampleControls"
        className="carousel slide mb-4"
        style={{ padding: "20px 0" }}
        data-ride="carousel"
      >
        <div className="container carousel-inner">
          {[...Array(Math.ceil(totalImages / imagesPerSlide))].map((_, slideIndex) => (
            <div key={slideIndex} className={`carousel-item${slideIndex === 0 ? " active" : ""}`}>
              <div className="row">
                {[...Array(imagesPerSlide)].map((_, index) => {
                  const imageIndex = (slideIndex * imagesPerSlide + index) % totalImages + 1; // Calculate image index with modulo operator
                  return (
                    <div key={imageIndex} className="col d-flex justify-content-center">
                      <img
                        className="d-block sponsor-img"
                        src={require(`../images/sponsor${imageIndex}.jpg`)}
                        alt={`Slide ${imageIndex}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sponsors;