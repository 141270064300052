import React from 'react'
import '../style/Footer.css';
import { FaTwitter, FaEnvelope , FaPhone, FaMapMarkerAlt, FaInstagram, FaFacebookF, FaWhatsapp } from 'react-icons/fa';

export const Footer = () => {
  return (
    <footer class="footer-10">
			<div class="container">
				<div class="row mb-5 pb-3 no-gutters">
					<div class="col-md-4 mb-md-0 mb-4 d-flex">
						<div class="con con-1 w-100 py-5">
							<div class="con-info w-100 text-center">
								<div class="icon2 d-flex align-items-center justify-content-center">
									<span class="ion-ios-call">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
										</svg>
									</span>
								</div>
								<div class="text">
									<span className='mb-2' onClick={() => window.location.href = 'tel:+33983644642'}> FR 9 83 64 46 42</span><br/>
									<span className='mb-2' onClick={() => window.location.href = 'tel:+33744192665'}> FR 7 44 19 26 65</span><br/>
									<span className='mb-2' onClick={() => window.location.href = 'tel:+212698029121'}> MA 7 75 42 72 94</span><br/>
									<span onClick={() => window.location.href = 'tel:+33652861823'}> FR 6 52 86 18 23</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-md-0 mb-4 d-flex" onClick={() => window.location.href = 'mailto:contact@am-bc.fr'}>
						<div class="con con-2 w-100 py-5">
							<div class="con-info w-100 text-center">
								<div class="icon2 d-flex align-items-center justify-content-center">
									<span class="ion-ios-mail"><FaEnvelope /></span>
								</div>
								<div class="text">
									<span>contacts@am-bc.fr</span>
									
								</div>
								<span>Devlop95@gmail.com</span>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-md-0 mb-4 d-flex">
						<div class="con con-3 w-100 py-5">
							<div class="con-info w-100 text-center">
								<div class="icon2 d-flex align-items-center justify-content-center">
									<span class="ion-ios-pin"><FaMapMarkerAlt /></span>
								</div>
								<div class="text">
									<span>5 Av. du Général de Gaulle, 92220 Bagneux, France</span>
								</div>
							</div>
						</div>
					</div>
				</div>
					{<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-5 mb-md-0 mb-4 text-center">
	
      <form action="#" class="subscribe-form">
        <div class="form-group">
          <input type="text" class="form-control rounded-left" placeholder="Enter email address"/>
        </div>
        <div class="form-group">
		<br />
    
          <button type="submit" class="btn btn-primary">Subscribe</button>
        </div>
        <span class="subheading">Get digital marketing updates in your mailbox</span>
      </form>
    </div>
  </div>
</div>
}
				<div class="row mt-5 pt-4 border-top" style={{marginBottom:'0px'}}>
          <div class="col text-center text-md-center">
          	<ul class="ftco-footer-social p-0">
              <li class="ftco-animate"><a href="https://www.facebook.com/amctravelsagency?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" data-placement="top" title="Facebook"><span class="ion-logo-facebook"><FaFacebookF /></span></a></li>
              <li class="ftco-animate"><a href="https://www.instagram.com/amc_travels_/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" data-placement="top" title="Instagram"><span class="ion-logo-instagram"><FaInstagram /></span></a></li>
            </ul>
          </div>
		  <div className='w-100 text-center '>
			<p className="text-white ">Copyright © AMC Travels tous droits réservés</p>
		  </div>
		  { <a className=' text-center company'  href="https://www.instagram.com/agafiredigital/" target="_blank" rel="noopener noreferrer">
			<p className="text-white ">
				Developed By : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-fire mb-2" viewBox="0 0 16 16">
  					<path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
				</svg> FireDigital
			</p>
		  </a> }
        </div>
			</div>
		</footer>
  )
}

export default Footer;