import React, { useState, useEffect } from 'react'
import { Sidebar } from '../components/Sidebar'
import '../style/packsPage.css';
import '../style/GestionOHPack.css';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import axios from 'axios';
import Statistics from '../components/Statistics';
import {api} from "../utils/constants";

export const GestionSimplePacks = () => {
    const [packs, setPacks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      const apiUrl = 'simplepacks/';
    
      api.get(apiUrl)
        .then((response) => {
          const reversedPacks = response.data.reverse();
          setPacks(reversedPacks);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
      });
        
    }, []);

    const handleDelete = (packId) => {
      if (window.confirm('Es-tu sûr de vouloir supprimer le pack?')) {
        axios.delete(`https://e.amctravel.net/simplepacks/${packId}/`)
          .then(response => {
            setPacks(prevEvents => prevEvents.filter(pack => pack.id !== packId));
          })
          .catch(error => {
            console.log(error);
          });
      }
    };
    return (
        <div className='container'>
            <Statistics/>
            <h3>Gestion Packs Simple</h3>
            
            <div className='row'>
              <div className='col d-flex justify-content-center'>
                <button className='btn btn-primary' onClick={() => navigate('/add-simple-pack')}>Ajouter un pack</button>
              </div>
            </div>
            <div className="table-responsive mt-2">
            <table className="table">
  <thead>
    <tr>
      <th colSpan={2} className='text-center'>Pack</th>
      <th>Date départ</th>
      <th>Date d'arrivée</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {packs.map((pack) => (
      <tr key={pack.id}> 
        <td className='align-middle'><img className="table-pack-image" src={pack.image} /></td>
        <td className='align-middle'>{pack.label}</td>
        <td className='align-middle'>{new Date(pack.date_aller).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
        <td className='align-middle'>{new Date(pack.date_retour).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
        <td className='align-middle'>
          <IconButton onClick={() => {navigate(`/edit-simple-pack/${pack.id}`)}} style={{color:'rgb(238, 168, 6)'}}>
            <Edit  />
          </IconButton>
          <IconButton onClick={() => {handleDelete(pack.id)}} style={{color:'darkred'}}>
            <Delete />
          </IconButton>
        </td>
      </tr>
    ))}
  </tbody>
</table>

            </div>
        </div>
  )
}

export default GestionSimplePacks;
