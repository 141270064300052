import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../style/reservation.css';
import { Select, MenuItem } from '@material-ui/core';
import Footer from '../components/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Statistics from '../components/Statistics';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {api} from "../utils/constants";

export const ReservationsSimple = () => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservationStatus, setReservationStatus] = useState({});
  const [packs, setPacks] = useState({}); // Store packs in an object
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [filter, setFilter] = useState("");



  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const apiUrl = 'simplereservations/';

    api
      .get(apiUrl)
      .then((response) => {
        const initialStatus = {};
        response.data.reverse().forEach((reservation) => {
          initialStatus[reservation.id] = reservation.status;
        });
        console.log(response.data.reverse());
        setReservations(response.data.reverse());
        setFilteredReservations(response.data.reverse());
        setReservationStatus(initialStatus);
      })
      .catch((error) => {
        console.error('Error fetching reservations:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch pack data for each reservation and store it in packs
    const fetchPackData = async () => {
      const updatedPacks = {}; // Create an object to store packs

      for (const reservation of reservations) {
        const packApiUrl = `simplepacks/${reservation.id_pack}/`;
        try {
          const response = await api.get(packApiUrl);
          updatedPacks[reservation.id_pack] = response.data; // Store the pack using id_pack as the key
        } catch (error) {
          console.error('Error fetching pack data:', error);
        }
      }

      setPacks(updatedPacks);
      console.log(packs);
    };

    if (reservations.length > 0) {
      fetchPackData();
    }
  }, [reservations]);

  const openModalDetails = async (id) => {
    try {
      const response = await api.get(`simplereservations/${id}/`);
      if (response.status === 200) {
        setDetails(response.data);
        openModal();
      } else {
        console.error('Failed to fetch ohreservation data');
      }
    } catch(error){
      console.error('Error fetching ohreservation data:', error);
    }
  }
  const downloadFile = (filePath, customFileName) => {
    const link = document.createElement('a');
    link.href = filePath;
  
    // Extract the original file extension from the file path
    const fileExtension = filePath.split('.').pop();
  
    // Set the custom file name with the original extension
    link.download = `${customFileName}.${fileExtension}`;
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleStatusChange = async (e , id) => {
      try {
        const response = await api.patch(`simplereservations/${id}/`, {'status' : e.target.value} ,{ 
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        });
        if (response.status === 200){
          toast.success("Status mis à jour avec succès !");
          setReservationStatus({
            ...reservationStatus,
            [id]: e.target.value,
          });
        }

      } catch (error){
        console.error(error);
      }
  };

  const handleDelete = (Id) => {
    if (window.confirm('Es-tu sûr de vouloir supprimer la réservation?')) {
      axios.delete(`https://e.amctravel.net/simplereservations/${Id}/`)
        .then(response => {
          setReservations(prevEvents => prevEvents.filter(reservation => reservation.id !== Id));
          setFilteredReservations(prevEvents => prevEvents.filter(reservation => reservation.id !== Id));

        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const HandleFilter = (e) => {
    setFilter(e.target.value)
    if(e.target.value === ""){
      setFilteredReservations(reservations);
    }
    else{
      const filteredReservations = reservations.filter((reservation) => {
        return reservation.status === e.target.value;
      });
      setFilteredReservations(filteredReservations);
    }
  }


  return (
    <div className='container' id="root">
      <ToastContainer/>
      <Statistics/>
      <h3>Réservation Simple</h3>
      <div className='row d-flex justify-content-center'>
  <div className='col-5'>
      <div className=''>
          <label className="w-100 text-center"><b>Filtrer par status réservation :</b></label><br/>
          <Select className="w-100 text-center" onChange={(e) => HandleFilter(e)} value={filter} displayEmpty>
            <MenuItem style={{color:''}} value=""><span>-- Selectionner --</span></MenuItem>
            <MenuItem style={{color:'orange'}} value="En attente..."><span style={{color:'orange'}}>En attente...</span></MenuItem>
            <MenuItem style={{color:'darkgreen'}} value="Confirmée"><span style={{color:'darkgreen'}}>Confirmée</span></MenuItem>
            <MenuItem style={{color:'darkred'}} value="Annulée"><span style={{color:'darkred'}}>Annulée</span></MenuItem>
        </Select>
      </div>
  </div>
</div>
      <div className="table-responsive mt-4">
        <table className="table">
          <thead>
            <tr>
              <th>Pack</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Nationalité</th>
              <th>Téléphone</th>
              <th style={{width:'150px'}} className='text-center'>Status</th>
              <th className='text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredReservations.length > 0 && (
              filteredReservations.map((reservation) => (
                packs[reservation.id_pack] && packs[reservation.id_pack].label && (
                <tr key={reservation.id}>
                  <td className='align-middle'>{packs[reservation.id_pack].label}</td>
                  <td className='align-middle'>{reservation.nom}</td>
                  <td className='align-middle'>{reservation.prenom}</td>
                  <td className='align-middle'>{reservation.nationality}</td>
                  <td className='align-middle'>{reservation.tele}</td>
                  <td className='align-middle text-center'>
                    <Select style={{width:'120px'}} value={reservationStatus[reservation.id] || "En attente..."} onChange={(e) => handleStatusChange(e, reservation.id)} displayEmpty>
                      <MenuItem style={{color:'orange'}} value="En attente..."><span style={{color:'orange'}}>En attente...</span></MenuItem>
                      <MenuItem style={{color:'darkgreen'}} value="Confirmée"><span style={{color:'darkgreen'}}>Confirmée</span></MenuItem>
                      <MenuItem style={{color:'darkred'}} value="Annulée"><span style={{color:'darkred'}}>Annulée</span></MenuItem>
                    </Select>
                  </td>
                  <td className='align-middle text-center'>
                      <button className="btn btn-info" style={{width:'110px',fontSize:'12px'}} onClick={() => openModalDetails(reservation.id)}>Voir les infos</button>
                  </td>
                  <td className='align-middle text-center'>
                  <IconButton onClick={() => {handleDelete(reservation.id)}} style={{color:'darkred'}}>
                    <Delete />
                  </IconButton>
                  </td>
                </tr>
              ))
              )
            )}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      {Object.keys(details).length > 0 && (
        <>
          <div className='row'>
              <div className='col'>
              </div>
              <div className='col'>
                <h2 className='text-center'>Details Reservation</h2>
              </div>
              <div className='col '>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={closeModal} style={{cursor:'pointer', float:'right'}} width="32" height="32" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </div>
          </div>  

          <div className='row p-3 mt-3'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <table className='details-table'>
                <tr>
                  <th>Nom :</th>
                  <td>{details.nom}</td>
                </tr>
                <tr>
                  <th>Prenom :</th>
                  <td>{details.prenom}</td>
                </tr>
                <tr>
                  <th>Nationalité :</th>
                  <td>{details.nationality}</td>
                </tr>
                <tr>
                  <th>Téléphone :</th>
                  <td>{details.tele}</td>
                </tr>
                <tr>
                  <th>Email :</th>
                  <td>{details.email}</td>
                </tr>
                <tr>
                  <th>Ville :</th>
                  <td>{details.ville}</td>
                </tr>

                
              </table>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <table className='details-table'>
                <tr>
                  <th>Adresse :</th>
                  <td>{details.address}</td>
                </tr>
                <tr>
                  <th>Code postale :</th>
                  <td>{details.codePostal}</td>
                </tr>
                <tr>
                  <th>Num Passeport :</th>
                  <td>{details.numPasseport} <b>expire</b> le {details.expPasseport}</td>
                </tr>
                {details.isSejourChecked && (
                  <tr>
                    <th>Num Carte de Sejour :</th>
                    <td>{details.numCarte} <b>expire</b> le {details.expCarte}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className='row'>
                <div className='col d-flex justify-content-center'>
                <table className='details-table'>
                  <tr>
                    <th colSpan={3} style={{fontSize:'25px'}} className='text-center'>DOCUMENTS</th>
                  </tr>
                <tr>
                  <td><button className='btn btn-info' onClick={() => { window.open(`${details.passeport}`, '_blank'); }}>Passeport</button></td>
                  <td><button className='btn btn-info' onClick={() => { window.open(`${details.cin}`, '_blank'); }}>CIN</button></td>
                  {details.isSejourChecked && (
                    <td><button className='btn btn-info' onClick={() => { window.open(`${details.carteSejour}`, '_blank'); }}>Carte Sejour</button></td>
                  )}
                </tr>
              </table>
                </div>
          </div>
        </>
        )} 
    </Modal>

    </div>
  );
}

export default ReservationsSimple;