import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import '../style/detailsOmra.css';

import { FloatingWhatsApp } from "react-floating-whatsapp";
import Avatar from "../icons/Avatar.png";
import CarouselPacks from "../components/CarouselPacks";
import OmraInformation from "../components/OmraInformation";
import { api } from "../utils/constants";

const OmraPage = () => {
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    api
      .get("omrahajjpacks/")
      .then((response) => {
        // Filter packs with type === "omra"
        const omraPacks = response.data.filter((pack) => pack.type === "omra");
        // Reverse the order of packs based on their IDs
        const reversedPacks = omraPacks.reverse();
        setPacks(reversedPacks);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const chatMessage = "Vous recevrez une réponse dans moins de 24 heures.";

  return (
    <div>
      <div className='nav-cont'>
      <Navbar />
      </div>
      <div>
        <CarouselPacks packs={packs} />
      </div>

      <OmraInformation />

      <div style={{ height: "50px" }}></div>

      {/* Floating WhatsApp button */}
      <FloatingWhatsApp
        phoneNumber="+33744192665"
        accountName="AMC Travels"
        avatar={Avatar}
        statusMessage={chatMessage}
        chatMessage={chatMessage}
        styles={{
          left: "10px",
          bottom: "10px",
          zIndex: 100,
          width: "500px",
        }}
      />
      <span id="contact-us">
        <Footer />
      </span>
    </div>
  );
};

export default OmraPage;
