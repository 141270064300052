import React, { useState, useEffect, useRef } from "react";
import { FaCalendar } from "react-icons/fa";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PackCardSimple from "../components/PackCardSimple";
import PackCardHO from "../components/PackCardHO";
import Sponsors from "../components/Sponsors";
import TestimonialSlider from "../components/TestimonialSlider";
import "../style/Home.css";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/fr";
import Mekkah from "../icons/mekkah.png";
import Budget from "../icons/budget.png";
import Camera from "../icons/camera.png";
import Travel from "../icons/travel.png";
import Avatar from "../icons/Avatar.png";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import "react-intl-tel-input/dist/main.css";
import PhoneInput from "react-intl-tel-input";
import AMC1 from "../images/AmcImage1.jpeg";
import AMC2 from "../images/AmcImage2.jpeg";
import AMC3 from "../images/AmcImage3.jpeg";
import AMC4 from "../images/AmcImage4.jpeg";
import { api } from "../utils/constants";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import { FloatingWhatsApp } from "react-floating-whatsapp";

const testimonials = [
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Abdul Rahman Dubois",
    description:
      "Une expérience incroyable! Je suis tellement reconnaissant d'avoir eu l'occasion de faire le Hajj avec votre aide.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Pierre Leclerc",
    description:
      "Merci pour cette expérience inoubliable! Votre équipe a rendu le pèlerinage si facile et agréable. Merci pour cette expérience inoubliable! Votre équipe a rendu le pèlerinage si facile et agréable. Merci pour cette expérience inoubliable! Votre équipe a rendu le pèlerinage si facile et agréable. Merci pour cette expérience inoubliable! Votre équipe a rendu le pèlerinage si facile et agréable.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Fatima Legrand",
    description:
      "Une expérience excellente dans l'accomplissement du Hajj! C'était un voyage spirituel qui m'a rempli de gratitude pour cette opportunité incroyable.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Mohammed Martin",
    description:
      "Une expérience inoubliable! Merci à l'équipe de soutien pour avoir fourni de l'aide et des conseils tout au long du voyage.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Marie Dupont",
    description:
      "Une expérience incroyable! Je suis tellement reconnaissante d'avoir eu l'occasion de faire le Hajj avec votre aide.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Jean Leclerc",
    description:
      "Merci pour cette expérience inoubliable! Votre équipe a rendu le pèlerinage si facile et agréable.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Sophie Lambert",
    description:
      "Un grand merci pour cette expérience unique! Votre professionnalisme et votre soutien ont rendu ce pèlerinage mémorable.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Philippe Bernard",
    description:
      "Je suis vraiment reconnaissant d'avoir eu l'occasion de faire le Hajj avec votre agence! Tout était parfaitement organisé.",
    numberOfStars: 5,
  },
  {
    image: "https://cdn.pixabay.com/photo/2015/12/09/22/26/people-1085695_1280.png",
    title: "Claire Roussel",
    description:
      "Une expérience extraordinaire! Je recommande vivement votre agence à tous ceux qui souhaitent accomplir le Hajj.",
    numberOfStars: 5,
  },
];


const images = [
  {
    original: AMC1,
    thumbnail: AMC1,
  },
  {
    original: AMC2,
    thumbnail: AMC2,
  },
  {
    original: AMC3,
    thumbnail: AMC3,
  },
  {
    original: AMC4,
    thumbnail: AMC4,
  },
];

Modal.setAppElement("#root");

function Home() {
  const navigate = useNavigate();
  const [dateAller, setDateAller] = useState(null);
  const [dateRetour, setDateRetour] = useState(null);
  const [dateAller2, setDateAller2] = useState(null);
  const [dateRetour2, setDateRetour2] = useState(null);
  const [depart, setDepart] = useState("");
  const [destination, setDestination] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const [countries, setCountries] = useState([]);
  const [ohpacks, setOHPacks] = useState([]);
  const [simplepacks, setSimplePacks] = useState([]);
  const moisFrancais = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];
  const [showModal, setShowModal] = useState(false);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nationality, setNationality] = useState("");
  const [tele, setTele] = useState("");
  const [email, setEmail] = useState("");
  const [numPasseport, setNumPasseport] = useState("");
  const [expPasseport, setExpPasseport] = useState("");
  const [isSejourChecked, setIsSejourChecked] = useState(false);
  const [numCarte, setNumCarte] = useState("");
  const [expCarte, setExpCarte] = useState("");
  const [address, setAddress] = useState("");
  const [ville, setVille] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [cin, setCin] = useState(null);
  const [passeport, setPasseport] = useState(null);
  const [carteSejour, setCarteSejour] = useState(null);
  const [price, setPrice] = useState("");
  const [hasScrolled, setHasScrolled] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const phoneInput = useRef();

  const [chatMessage, setChatMessage] = useState(
    "Cher client, merci de nous contacter"
  );

  const handleContactUsClick = () => {
    setChatMessage("Cher client, merci de nous contacter");
  };

  const closeModal = () => {
    // Close the modal
    setShowModal(false);
  };

  useEffect(() => {
    api
      .get("simplepacks/")
      .then((response) => {
        // Reverse the order of packs based on their IDs
        const reversedPacks = response.data.reverse();
        console.log(reversedPacks)
        setSimplePacks(reversedPacks);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    api
      .get("omrahajjpacks/")
      .then((response) => {
        // Filter packs with type === "omra"
        const omraPacks = response.data.filter((pack) => pack.type !== "hajj");
        // Reverse the order of packs based on their IDs
        const reversedPacks = omraPacks.reverse();
        setOHPacks(reversedPacks);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countriesInFrench = response.data.map((country) => ({
          label: country.translations.fra.common,
          value: country.translations.fra.common,
        }));
        setCountries(countriesInFrench);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const handleDatesChange = ({ startDate, endDate }) => {
    setDateAller(startDate);
    setDateRetour(endDate);
  };

  const customInputPhrases = {
    startDateInputLabel: "Date Début",
    endDateInputLabel: "Date Fin",
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div>
      <input value={value} onClick={onClick} ref={ref} />
      <FaCalendar onClick={onClick} />
    </div>
  ));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "49px",
      width: "220px", // Adjust the height as needed
      cursor: "pointer",
      marginBottom: "",
    }),
  };
  const customStyles2 = {
    control: (provided) => ({
      ...provided,
      width: "200px", // Adjust the height as needed
      cursor: "pointer",
    }),
  };

  const handleReservation = async (e) => {
    e.preventDefault();

    if (destination.value === depart.value) {
      toast.error("Le pays de départ est confondu avec le pays d'arrivée !");
    } else if (dateAller2 > dateRetour2) {
      toast.error("La date d'aller est aprés la date de retour !");
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    // Call recordVisitor when the App component mounts
    const recordVisitor = async () => {
      try {
        await api.post("record-visitor/");
      } catch (error) {
        console.error("Failed to record visitor:", error);
      }
    };

    recordVisitor();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(false);
    let formattedDateAller = 0;
    let formattedDateRetour = 0;
    if (dateAller2 !== null) {
      formattedDateAller = moment(dateAller2).format("YYYY-MM-DD");
      formattedDateRetour = moment(dateRetour2).format("YYYY-MM-DD");
    } else {
      formattedDateAller = moment(dateAller).format("YYYY-MM-DD");
      formattedDateRetour = moment(dateRetour).format("YYYY-MM-DD");
    }

    // Create a FormData object and append all the values
    const formData = new FormData();
    formData.append("depart", depart.value);
    formData.append("destination", destination.value);
    formData.append("dateAller", formattedDateAller);
    formData.append("dateRetour", formattedDateRetour);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("nationality", nationality.value);
    formData.append("tele", dialCode + " " + tele);
    formData.append("email", email);
    formData.append("numPasseport", numPasseport);
    formData.append("expPasseport", expPasseport);
    formData.append("isSejourChecked", isSejourChecked);
    formData.append("numCarte", numCarte);
    formData.append("expCarte", expCarte);
    formData.append("address", address);
    formData.append("ville", ville);
    formData.append("codePostal", codePostal);

    if (cin) {
      formData.append("cin", cin);
    }
    if (passeport) {
      formData.append("passeport", passeport);
    }
    if (carteSejour) {
      formData.append("carteSejour", carteSejour);
    }

    try {
      const response = await api.post("customreservations/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        setIsSejourChecked(false);
        setDepart("");
        setDestination("");
        setDateAller(null);
        setDateRetour(null);
        setNom("");
        setPrenom("");
        setAddress("");
        setCarteSejour(null);
        setCodePostal("");
        setCin(null);
        setEmail("");
        setExpCarte("");
        setExpPasseport("");
        setNationality("");
        setNumCarte("");
        setNumPasseport("");
        setTele("");
        setVille("");

        toast.success("Votre réservation a bien été faite !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.error("Failed to submit reservation");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handlePhoneNumberChange = (value, country, event, formattedValue) => {
    setTele(country.toString());
    setDialCode("+" + event.dialCode);
  };

  return (
    <span id="root">
      <FloatingWhatsApp
        phoneNumber="+33744192665"
        accountName="AMC Travels"
        avatar={Avatar}
        statusMessage="Vous recevrez une réponse dans moins de 24 heures."
        chatMessage={chatMessage} // Replace with your custom message
        styles={{
          left: "10px",
          bottom: "10px",
          zIndex: 100,
          width: "500px",
        }}
      />
      <ToastContainer />
      <div className="background-images w-100">
        <Navbar />
        <div className="container container-3 mt-5" id="accueil">

        <h1 className="text1">Agence de Voyage</h1>
        <h2 className="text2">et Location de Voitures</h2>
          <h1 className="text1">Découvrez</h1>
          <h2 className="text2">des destinations étonnantes</h2>
          <div className="row d-flex justify-content-center">
            <div className="col d-flex justify-content-center reservation-col">
              <form onSubmit={handleReservation} className="reservation-form">
                <div className="simple-reservation">
                  <div className="row">
                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                      <div className="icon-container">
                        <Select
                          styles={{ zIndex: 1000, ...customStyles }}
                          options={countries}
                          value={depart}
                          onChange={(e) => setDepart(e)}
                          search={true}
                          name="countries"
                          placeholder="Pays de départ"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                      <div className="icon-container">
                        <Select
                          styles={{ zIndex: 1000, ...customStyles }}
                          options={countries}
                          value={destination}
                          onChange={(e) => setDestination(e)}
                          search={true}
                          name="countries"
                          placeholder="Pays destination"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center">
                      <div
                        className="icon-container "
                        style={{ marginTop: "1.5px", overflow: "visible" }}
                      >
                        {/* <svg xmlns="http://www.w3.org/2000/svg" style={{color:'grey', margin:'0 10px'}} width="20" height="20" fill="currentColor" class="bi bi-calendar2-week" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
</svg> */}
                        <DateRangePicker
                          startDate={dateAller}
                          startDateId="startDate"
                          endDate={dateRetour}
                          endDateId="endDate"
                          onDatesChange={handleDatesChange}
                          focusedInput={focusedInput}
                          onFocusChange={(focused) => setFocusedInput(focused)}
                          numberOfMonths={2}
                          readOnly={true}
                          isRTL={false}
                          startDatePlaceholderText="Date :  Aller"
                          endDatePlaceholderText="Retour"
                          customArrowIcon="/"
                          style={{
                            zIndex: "10000",
                            width: "400px",
                            cursor: "pointer",
                          }}
                          locale="fr"
                          wrapperClassName="datePicker"
                          portalId="root2"
                          // popperProps={{
                          //   strategy: "fixed" // use this to make the popper position: fixed
                          // }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <button
                        className="btn btn-orange w-100"
                        style={{ height: "47px" }}
                      >
                        Réserver
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <form onSubmit={handleReservation}>
                <div className="mobile-reservation">
                  <div className="row">
                    <div className="col">
                      <div
                        class="input-group"
                        style={{ borderRadius: "5px 5px 0px 0px" }}
                      >
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img
                              src={require("../icons/planeUp.png")}
                              className="icon-image"
                            />
                          </span>
                          <Select
                            options={countries}
                            aria-describedby="basic-addon1"
                            styles={{ zIndex: 1000, ...customStyles2 }}
                            value={depart}
                            onChange={(e) => setDepart(e)}
                            search={true}
                            name="countries"
                            placeholder="Pays de départ"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img
                              src={require("../icons/planeDown.png")}
                              className="icon-image"
                            />
                          </span>
                          <Select
                            options={countries}
                            aria-describedby="basic-addon1"
                            styles={{ zIndex: 1000, ...customStyles2 }}
                            value={destination}
                            onChange={(e) => setDestination(e)}
                            search={true}
                            name="countries"
                            placeholder="Pays destination"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label
                        for=""
                        className="text-center"
                        style={{ borderRadius: "0px 0px 0px 0px" }}
                      >
                        Date Aller :
                      </label>
                      <div
                        class="input-group "
                        style={{ borderRadius: "0px 0px 0px 0px" }}
                      >
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-calendar2-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="date"
                          class="form-control"
                          value={dateAller2}
                          onChange={(e) => setDateAller2(e.target.value)}
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col">
                      <label for="dateRetourr" className="text-center">
                        Date Retour
                      </label>
                      <div
                        class="input-group"
                        style={{ borderRadius: "0px 0px 5px 5px" }}
                      >
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-calendar2-minus-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM6 10a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1H6z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="date"
                          class="form-control"
                          id="dateRetourr"
                          value={dateRetour2}
                          onChange={(e) => setDateRetour2(e.target.value)}
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col">
                      <button className="btn btn-primary w-100">
                        Réserver
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row container mx-auto"
        id="root2"
        style={{ marginTop: "-50px" }}
      >
        <div className="col-lg-3 mt-3 col-md-6 col-sm-12 d-flex justify-content-center text-center">
          <div className="element element-first">
            <img className="element-icon mt-3" src={Travel} alt="" />
            <h5 className="mt-4 element-header">
              Voyages Internationaux Experts
            </h5>
            <p>
              Des voyages internationaux planifiés avec expertise et soucis du
              détail.
            </p>
          </div>
        </div>
        <div className="col-lg-3 mt-3 col-md-6 col-sm-12 d-flex justify-content-center text-center">
          <div className="element">
            <img className="element-icon mt-3" src={Camera} alt="" />
            <h5 className="mt-4 element-header">
            Location de Voitures
            </h5>
            <p>AMC Travels vous garantit des souvenirs inoubliables.</p>
          </div>
        </div>
        <div className="col-lg-3 mt-3 col-md-6 col-sm-12 d-flex justify-content-center text-center">
          <div className="element">
            <img className="element-icon mt-3" src={Budget} alt="" />
            <h5 className="mt-4 element-header">Options Budgétaires Variées</h5>
            <p>
              Découvrez des options pour tous les budgets, adaptées à vos
              besoins.
            </p>
          </div>
        </div>
        <div className="col-lg-3 mt-3 col-md-6 col-sm-12 d-flex justify-content-center text-center">
          <div className="element">
            <img className="element-icon mt-3" src={Mekkah} alt="" />
            <h5 className="mt-4 element-header">Hajj & Omra Inclus</h5>
            <p className="para-element">
              Des forfaits tout compris pour vos voyages Hajj & Omra.
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Modal"
      >
        <div className="row modal-row">
          <div className="col w-100">
            <h2 className="text-center w-100">
              Informations personnelles
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={closeModal}
                style={{ cursor: "pointer", float: "right" }}
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </h2>
          </div>
        </div>
        <form className="modal-row" onSubmit={handleSubmit}>
          <h5 className="mb-3">Veuillez remplir le formulaire suivant :</h5>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="nom">Nom</label>
                <input
                  type="text"
                  class="form-control"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  id="nom"
                  placeholder="Nom"
                  required
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="prenom">Prénom</label>
                <input
                  type="text"
                  class="form-control"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  id="prenom"
                  placeholder="Prénom"
                  required
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="mail">Email</label>
                <input
                  type="mail"
                  class="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="mail"
                  placeholder="exemple@exemple.com"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
              <div class="form-group w-100">
                <label for="">Nationalité</label>
                <Select
                  options={countries}
                  value={nationality}
                  onChange={(e) => setNationality(e)}
                  search={true}
                  name="countries"
                  placeholder="Nationalité"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="numPasseport">Numéro du passeport</label>
                <input
                  type="text"
                  class="form-control"
                  value={numPasseport}
                  onChange={(e) => setNumPasseport(e.target.value)}
                  id="numPasseport"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
              <div class="form-group ">
                <label for="tele">Téléphone</label>
                <br />
                <PhoneInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  value={tele}
                  ref={phoneInput}
                  onPhoneNumberChange={handlePhoneNumberChange}
                  preferredCountries={["FR", "GB", "CA"]} // Set preferred countries (optional)
                  required
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="expPasseport">Date d'expiration du passeport</label>
                <input
                  type="date"
                  class="form-control"
                  value={expPasseport}
                  onChange={(e) => setExpPasseport(e.target.value)}
                  id="expPasseport"
                />
              </div>
            </div>
          </div>

          <div class="form-check mt-3 mb-3">
            <input
              class="form-check-input "
              checked={isSejourChecked}
              onChange={(e) => setIsSejourChecked(e.target.checked)}
              type="checkbox"
              id="Sejour"
            />
            <label
              class="form-check-label"
              style={{ fontSize: "18px", marginTop: "2px" }}
              for="Sejour"
            >
              Cocher si vous disposer d'une carte de séjour
            </label>
          </div>
          {isSejourChecked && (
            <div className="row mt-4">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="numSejour">Numéro de Carte</label>
                  <input
                    type="text"
                    class="form-control"
                    value={numCarte}
                    onChange={(e) => setNumCarte(e.target.value)}
                    id="numSejour"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="expSejour">Date d'expiration du Carte</label>
                  <input
                    type="date"
                    class="form-control"
                    value={expCarte}
                    onChange={(e) => setExpCarte(e.target.value)}
                    id="expSejour"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="carteSejour">Carte de séjour (Document)</label>
                  <input
                    type="file"
                    class="form-control"
                    onChange={(e) => setCarteSejour(e.target.files[0])}
                    id="carteSejour"
                  />
                </div>
              </div>
            </div>
          )}
          <hr />
          <h5 className="mt-3 mb-3">COORDONNÉES :</h5>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="address">Adresse postale</label>
                <input
                  type="text"
                  class="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  id="address"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="ville">Ville</label>
                <input
                  type="text"
                  class="form-control"
                  value={ville}
                  onChange={(e) => setVille(e.target.value)}
                  id="ville"
                  required
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="codePostal">Code postale</label>
                <input
                  type="text"
                  class="form-control"
                  value={codePostal}
                  onChange={(e) => setCodePostal(e.target.value)}
                  id="codePostal"
                  placeholder=""
                  required
                />
              </div>
            </div>
          </div>
          <hr />
          <h5 className="mt-3 mb-3">DOCUMENTS :</h5>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="cin">CIN</label>
                <input
                  type="file"
                  class="form-control"
                  onChange={(e) => setCin(e.target.files[0])}
                  id="cin"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="passeport">Passeport</label>
                <input
                  type="file"
                  class="form-control"
                  onChange={(e) => setPasseport(e.target.files[0])}
                  id="passeport"
                />
              </div>
            </div>
          </div>
          <p className="mt-4 mb-2">
            <b>Note :</b>Une fois que vous confirmez votre réservation, nous
            vous contacterons via WhatsApp ou par e-mail afin de valider votre
            demande.
          </p>
          <div className="row">
            <div className="col w-100">
              <button type="submit" className="btn w-100 btn-primary">
                Confirmer la reservation
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {simplepacks.length > 0 && (
        <div className="row mt-5 mb-4" id="nosPacks">
          <h3 className="w-100 text-center packs-title mb-4">
            Nos Packs
            <h5 className="packs-subtitle mt-2">
              Découvrez nos packs de voyage organisés exclusifs toute l'année !
            </h5>
          </h3>
          <div class="container-fluid">
            <div className=" container row  mx-auto">
              {simplepacks.map((pack) => (
                <PackCardSimple key={pack.id} pack={pack} navigate={navigate} />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Pack a destination HAJJ ET OMRA */}
      <div className="row mt-5 mb-4" id="hajjOmra">
        <h3 className="w-100 text-center packs-title mb-4">
          Nos Packs OMRA{" "}
          <h5 className="packs-subtitle mt-2">
            Découvrez OMRA toute l'année !
          </h5>
        </h3>
        <div className=" container row mx-auto">
          {ohpacks.map((pack) => (
            <PackCardHO key={pack.id} pack={pack} navigate={navigate} />
          ))}
        </div>
      </div>
      <section className="hp-section hp-features-sect">
    <div className="container">
    <br />
    <br />
    <br />
        <h2 className="section-heading text-center mb-4" style={{fontWeight: 'bold'}}>Nos Services</h2>
        <br />
        <div className="row">
            <div className="col-lg-4">
                <div className="hpfs-box" style={{ backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="hpw-avatar-cover">
                                <img src="https://cdn.mypanel.link/q29etl/q4njtz3y5rqh57ry.png" className="hpfs-box-img" alt="avatar" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="hpw-name" style={{ fontSize: '20px', marginBottom: '10px' }}>Notre Crédibilité</div>
                        </div>
                    </div>
                    <br />
                    <p className="hpfs-p" style={{ fontSize: '14px', color: 'black', textAlign: 'justify' }}>Nous sommes ici depuis 2011 et avons servi plus d'un million de personnes depuis. Nous avons également constaté que 80 % de notre clientèle est tellement satisfaite qu'elle recommande ses amis et revient même pour acheter davantage.</p>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="hpfs-box" style={{ backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="hpw-avatar-cover">
                                <img src="https://cdn.mypanel.link/q29etl/ntr9jqp578hapjki.png" className="hpfs-box-img" alt="avatar" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="hpw-name" style={{ fontSize: '20px', marginBottom: '10px' }}>Support Fiable</div>
                        </div>
                    </div>
                    <br />
                    <p className="hpfs-p" style={{ fontSize: '14px', color: 'black', textAlign: 'justify' }}>Notre agence de voyages est là pour vous à tout moment où vous en avez besoin. Nous comprenons que parfois des questions peuvent survenir ou des problèmes peuvent apparaître pour lesquels vous aurez besoin d'une aide supplémentaire. C'est pourquoi l'équipe de notre agence est disponible 24h/24 et 7j/7 - pour garantir votre succès et votre satisfaction, quelle que soit l'heure à laquelle vous avez besoin de nous.</p>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="hpfs-box" style={{ backgroundColor: 'white', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="hpw-avatar-cover">
                                <img src="https://cdn.mypanel.link/q29etl/63d0rmbg83w43onm.png" className="hpfs-box-img" alt="avatar" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="hpw-name" style={{ fontSize: '20px', marginBottom: '10px' }}>Payez Selon Vos Besoins</div>
                        </div>
                    </div>
                    <br />
                    <p className="hpfs-p" style={{ fontSize: '14px', color: 'black', textAlign: 'justify' }}>Avec notre agence de voyages, pas de crainte de gaspiller de l'argent. Tous nos services sont payés selon vos besoins, vous êtes donc libre d'arrêter à tout moment. Ou, si vous vous sentez prêt à en ajouter encore plus après avoir vu les résultats, vous pouvez ajouter et augmenter vos interactions à tout moment.</p>
                </div>
            </div>
        </div>
    </div>
</section>

      <div>
        <Sponsors />
      </div>


{ 
    <div>
      <TestimonialSlider testimonials={testimonials} /> 
    </div>
}


      <h3
        className="w-100 text-center mt-5 packs-title"
        id="about-us"
        style={{ padding: "20px" }}
      >
        À Propos de AMC Travel
      </h3>
      <div className=" container justify-content-center">
        <div className=" row mb-5 contact-row">
          <div className="mt-4 col-lg-6 col-md-12 col-sm-12 ">
            <div className="img-gallery-container">
              <ImageGallery items={images} />
            </div>
          </div>

          <div className="mt-4 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.196149097127!2d2.2977412!3d48.7972349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e670ea7db2a2cd%3A0x62ceae734e207cc0!2s5%20Av.%20du%20G%C3%A9n%C3%A9ral%20de%20Gaulle%2C%2092220%20Bagneux%2C%20France!5e0!3m2!1sfr!2sma!4v1695320474681!5m2!1sfr!2sma"
              className="map"
              height="490"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      {/* Footer */}
      <span id="contact-us">
        <Footer />
      </span>
    </span>
  );
}

export default Home;
