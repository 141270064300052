  import React, { useState, useEffect } from 'react';
  import { Select, MenuItem } from '@material-ui/core';
  import axios from 'axios';
  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate, useParams } from 'react-router-dom';
  import {api} from "../utils/constants";

  export const EditOmraHajj = () => {
    
    const [label, setLabel] = useState("");
    const [type, setType] = useState("");
    const [Image, setImage] = useState(null);
    const [dateAller, setDateAller] = useState("");
    const [dateRetour, setDateRetour] = useState("");
    const [hotelMedina, setHotelMedina] = useState("");
    const [checkInHM, setCheckInHM] = useState("");
    const [checkOutHM, setCheckOutHM] = useState("");
    const [hotelMakkah, setHotelMakkah] = useState("");
    const [checkInHK, setCheckInHK] = useState("");
    const [checkOutHK, setCheckOutHK] = useState("");

    // Information vols aller
    const [allerCompany, setAllerCompany] = useState("");
    const [allerVilleDepart, setAllerVilleDepart] = useState("");
    const [allerVilleArrive, setAllerVilleArrive] = useState("");
    const [allerDateDepart, setAllerDateDepart] = useState("");
    const [allerDateArrive, setAllerDateArrive] = useState("");
    const [allerHeureDepart, setAllerHeureDepart] = useState("");
    const [allerHeureArrive, setAllerHeureArrive] = useState("");
    const [isEscaleAllerChecked, setIsEscaleAllerChecked] = useState(false);
    const [allerVilleEscale, setAllerVilleEscale] = useState("");
    const [allerDateEscale, setAllerDateEscale] = useState("");
    const [allerHeureEscale, setAllerHeureEscale] = useState("");
    const [allerDateReprise, setAllerDateReprise] = useState("");
    const [allerHeureReprise, setAllerHeureReprise] = useState("");

    // Informations vols retour
    const [retourCompany, setRetourCompany] = useState("");
    const [retourVilleDepart, setRetourVilleDepart] = useState("");
    const [retourVilleArrive, setRetourVilleArrive] = useState("");
    const [retourDateDepart, setRetourDateDepart] = useState("");
    const [retourDateArrive, setRetourDateArrive] = useState("");
    const [retourHeureDepart, setRetourHeureDepart] = useState("");
    const [retourHeureArrive, setRetourHeureArrive] = useState("");
    const [isEscaleRetourChecked, setIsEscaleRetourChecked] = useState(false);
    const [retourVilleEscale, setRetourVilleEscale] = useState("");
    const [retourDateEscale, setRetourDateEscale] = useState("");
    const [retourHeureEscale, setRetourHeureEscale] = useState("");
    const [retourDateReprise, setRetourDateReprise] = useState("");
    const [retourHeureReprise, setRetourHeureReprise] = useState("");

    const [singlePrice, setSinglePrice] = useState("");
    const [doublePrice, setDoublePrice] = useState("");
    const [triplePrice, setTriplePrice] = useState("");
    const [quadPrice, setQuadPrice] = useState("");
    
    const [isAutreMakkahChecked, setIsAutreMakkahChecked] = useState(false);
    useEffect(() => {
      if (hotelMakkah !== "" && hotelMakkah !== "Hotel Sheraton 4*" && hotelMakkah !== "Conrad 5*" && hotelMakkah !== "Hilton Suit 5*" && hotelMakkah !== "Hotel Marriott 5*" && hotelMakkah !== "Un hôtel exceptionnel"){
        setIsAutreMakkahChecked(true);
      }
    }, [hotelMakkah]);

    const [isAutreMadinahChecked, setIsAutreMadinahChecked] = useState(false);
    useEffect(() => {
      if (hotelMedina !== "" && hotelMedina !== "Millennium al Aqeeq 5*" && hotelMedina !== "Madinah Concorde hotel 3" && hotelMedina !== "Nusuk Al Madinah hotel 3.5*" && hotelMedina !== "Un hôtel exceptionnel"){
        setIsAutreMadinahChecked(true);
      }
    }, [hotelMedina]);


    const [isDepartVolChecked, setIsDepartVolChecked] = useState(false);
    useEffect(() => {
      if (allerCompany !== "" && allerCompany !== "SAUDIA AIRLINES" && allerCompany !== "TURKISH AIRLINES" && allerCompany !== "ROYAL JORDANIAN" && allerCompany !== "EgyptAir"){
        setIsDepartVolChecked(true);
      }
    }, [allerCompany]);
    const [isRetourVolChecked, setIsRetourVolChecked] = useState(false);
    useEffect(() => {
      if (retourCompany !== "" && retourCompany !== "SAUDIA AIRLINES" && retourCompany !== "TURKISH AIRLINES" && retourCompany !== "ROYAL JORDANIAN" && retourCompany !== "EgyptAir"){
        setIsRetourVolChecked(true);
      }
    }, [retourCompany]);


    const [isPetitDejChecked, setIsPetitDejChecked] = useState(false);
    const [isDejChecked, setIsDejChecked] = useState(false);
    const [isDinnerChecked, setIsDinnerChecked] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const [pack, setPack] = useState({});


    const handleHotelMadinah = (e) => {
      setHotelMedina(e.target.value);
      if(e.target.value === "Autre"){
        setIsAutreMadinahChecked(true);
        setHotelMedina("");
      }
      else{
        setIsAutreMadinahChecked(false)
      }
    };

    const handleHotelMakkah = (e) => {
      if(e.target.value === "Autre"){
        setIsAutreMakkahChecked(true);
        setHotelMakkah("");
      }
      else{
        setHotelMakkah(e.target.value);
        setIsAutreMakkahChecked(false)
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get(`omrahajjpacks/${id}/`);
          if (response.status === 200) {
            console.log(response.data.aller_company);

            setPack(response.data);
            setLabel(response.data.label);
            setType(response.data.type);
            
            setDateAller(response.data.date_aller);
            setDateRetour(response.data.date_retour);
          
            // Hotels
  setHotelMedina(response.data.hotel_medina);
  setCheckInHM(response.data.check_in_hm);
  setCheckOutHM(response.data.check_out_hm);
  setHotelMakkah(response.data.hotel_makkah);
  setCheckInHK(response.data.check_in_hk);
  setCheckOutHK(response.data.check_out_hk);
  // Information vols aller
 
  setAllerVilleDepart(response.data.aller_ville_depart);
  setAllerVilleArrive(response.data.aller_ville_arrive);
  setAllerDateDepart(response.data.aller_date_depart);
  setAllerDateArrive(response.data.aller_date_arrive);
  setAllerHeureDepart(response.data.aller_heure_depart);
  setAllerHeureArrive(response.data.aller_heure_arrive);
  setIsEscaleAllerChecked(response.data.escale_aller);
  setAllerVilleEscale(response.data.aller_ville_escale);
  if(response.data.escale_aller){
    setAllerDateEscale(response.data.aller_date_escale);
    setAllerHeureEscale(response.data.aller_heure_escale);
    setAllerDateReprise(response.data.aller_date_reprise);
    setAllerHeureReprise(response.data.aller_heure_reprise);
  }
  else{
    setAllerDateEscale(null);
    setAllerHeureEscale(null);
    setAllerDateReprise(null);
    setAllerHeureReprise(null);
  }

  // Informations vols retour
  setRetourCompany(response.data.retour_company);
  setRetourVilleDepart(response.data.retour_ville_depart);
  setRetourVilleArrive(response.data.retour_ville_arrive);
  setRetourDateDepart(response.data.retour_date_depart);
  setRetourDateArrive(response.data.retour_date_arrive);
  setRetourHeureDepart(response.data.retour_heure_depart);
  setRetourHeureArrive(response.data.retour_heure_arrive);
  setIsEscaleRetourChecked(response.data.escale_retour);
  setRetourVilleEscale(response.data.retour_ville_escale);
  if(response.data.escale_retour){
    setRetourDateEscale(response.data.retour_date_escale);
    setRetourHeureEscale(response.data.retour_heure_escale);
    setRetourDateReprise(response.data.retour_date_reprise);
    setRetourHeureReprise(response.data.retour_heure_reprise);
  }
  else{
    setRetourDateEscale(null);
    setRetourHeureEscale(null);
    setRetourDateReprise(null);
    setRetourHeureReprise(null);
  }


  // Prices
  setSinglePrice(response.data.single_price);
  setDoublePrice(response.data.double_price);
  setTriplePrice(response.data.triple_price);
  setQuadPrice(response.data.quad_price);
            
  setIsPetitDejChecked(response.data.petit_dejeuner);
  setIsDejChecked(response.data.dejeuner);
  setIsDinnerChecked(response.data.dinner);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

      fetchData();
    }, [id]);

    const handleEscaleAllerChange = (e) => {
      if (!e.target.checked) {
        setAllerVilleEscale("");
        setAllerDateEscale(null);
        setAllerHeureEscale(null);
        setAllerDateReprise(null);
        setAllerHeureReprise(null);
      }
      setIsEscaleAllerChecked(e.target.checked);
      setPack({ ...pack, escale_retour: e.target.value });

    };

    const handleEscaleRetourChange = (e) => {
      if (!e.target.checked) {
        setRetourVilleEscale("");
        setRetourDateEscale(null);
        setRetourHeureEscale(null);
        setRetourDateReprise(null);
        setRetourHeureReprise(null);
      }
      setIsEscaleRetourChecked(e.target.checked);
      setPack({ ...pack, escale_retour: e.target.value });
    };

    const handleTypeChange = (e) => {
      setPack({ ...pack, type: e.target.value });
      setType(e.target.value);
    };

    const handlePackImageChange = (e) => {
      setPack({ ...pack, image: e.target.files[0] });
      setImage(e.target.files[0]);
    };

    const handleFormSubmit = async (e) => {
      e.preventDefault();
      console.log(retourDateEscale);
      console.log(Image);

      const formData = new FormData();
      formData.append('label', label);
      formData.append('type', type);
      if(Image != null){
        formData.append('image', Image);
      }
      if (dateAller === null){
        formData.append('date_aller', "");
      }
      else{
        formData.append('date_aller', dateAller);
      }
      if (dateRetour === null){
        formData.append('date_retour', "");
      }
      else{
        formData.append('date_retour', dateRetour);
      }
      if (checkInHM === null){
        formData.append('check_in_hm', "");
      }
      else{
        formData.append('check_in_hm', checkInHM);
      }
      if (checkOutHM === null){
        formData.append('check_out_hm', "");
      }
      else{
        formData.append('check_out_hm', checkOutHM);
      }
      if (checkInHK === null){
        formData.append('check_in_hk', "");
      }
      else{
        formData.append('check_in_hk', checkInHK);
      }
      if (checkOutHK === null){
        formData.append('check_out_hk', "");
      }
      else{
        formData.append('check_out_hk', checkOutHK);
      }
      if (hotelMedina === null){
        formData.append('hotel_medina', "");
      }
      else{
        formData.append('hotel_medina', hotelMedina);
      }
      if (hotelMakkah === null){
        formData.append('hotel_makkah', "");
      }
      else{
        formData.append('hotel_makkah', hotelMakkah);
      }

      if (allerCompany === null){
        formData.append('aller_company', "");
      }
      else{
        formData.append('aller_company', allerCompany);
      }
      if (allerVilleDepart === null){
        formData.append('aller_ville_depart', "");
      }
      else{
        formData.append('aller_ville_depart', allerVilleDepart);
      }
      if (allerVilleArrive === null){
        formData.append('aller_ville_arrive', "");
      }
      else{
        formData.append('aller_ville_arrive', allerVilleArrive);
      }
      if (allerDateDepart === null){
        formData.append('aller_date_depart', "");
      }
      else{
        formData.append('aller_date_depart', allerDateDepart);
      }
      if (allerDateArrive === null){
        formData.append('aller_date_arrive', "");
      }
      else{
        formData.append('aller_date_arrive', allerDateArrive);
      }
      if (allerHeureDepart === null){
        formData.append('aller_heure_depart', "");
      }
      else{
        formData.append('aller_heure_depart', allerHeureDepart);
      }
      if (allerHeureArrive === null){
        formData.append('aller_heure_arrive', "");
      }
      else{
        formData.append('aller_heure_arrive', allerHeureArrive);
      }

      formData.append('escale_aller', isEscaleAllerChecked);

      if(isEscaleAllerChecked){
        if (allerVilleEscale === null){
          formData.append('aller_ville_escale', "");
        }
        else{
          formData.append('aller_ville_escale', allerVilleEscale);
        }
        if (allerDateEscale === null){
          formData.append('aller_date_escale', "");
        }
        else{
          formData.append('aller_date_escale', allerDateEscale);
        }
        if (allerHeureEscale === null){
          formData.append('aller_heure_escale', "");
        }
        else{
          formData.append('aller_heure_escale', allerHeureEscale);
        }
        if (allerDateReprise === null){
          formData.append('aller_date_reprise', "");
        }
        else{
          formData.append('aller_date_reprise', allerDateReprise);
        }
        if (allerHeureReprise === null){
          formData.append('aller_heure_reprise', "");
        }
        else{
          formData.append('aller_heure_reprise', allerHeureReprise);
        }
      }

      if (retourCompany === null){
        formData.append('retour_company', "");
      }
      else{
        formData.append('retour_company', retourCompany);
      }
      if (retourVilleDepart === null){
        formData.append('retour_ville_depart', "");
      }
      else{
        formData.append('retour_ville_depart', retourVilleDepart);
      }
      if (retourVilleArrive === null){
        formData.append('retour_ville_arrive', "");
      }
      else{
        formData.append('retour_ville_arrive', retourVilleArrive);
      }
      if (retourDateDepart === null){
        formData.append('retour_date_depart', "");
      }
      else{
        formData.append('retour_date_depart', retourDateDepart);
      }
      if (retourDateArrive === null){
        formData.append('retour_date_arrive', "");
      }
      else{
        formData.append('retour_date_arrive', retourDateArrive);
      }
      if (retourHeureDepart === null){
        formData.append('retour_heure_depart', "");
      }
      else{
        formData.append('retour_heure_depart', retourHeureDepart);
      }
      if (retourHeureArrive === null){
        formData.append('retour_heure_arrive', "");
      }
      else{
        formData.append('retour_heure_arrive', retourHeureArrive);
      }

      formData.append('escale_retour', isEscaleRetourChecked);

      if(isEscaleRetourChecked){
        if (retourVilleEscale === null){
          formData.append('retour_ville_escale', "");
        }
        else{
          formData.append('retour_ville_escale', retourVilleEscale);
        }
        if (retourDateEscale === null){
          formData.append('retour_date_escale', "");
        }
        else{
          formData.append('retour_date_escale', retourDateEscale);
        }
        if (retourHeureEscale === null){
          formData.append('retour_heure_escale', "");
        }
        else{
          formData.append('retour_heure_escale', retourHeureEscale);
        }
        if (retourDateReprise === null){
          formData.append('retour_date_reprise', "");
        }
        else{
          formData.append('retour_date_reprise', retourDateReprise);
        }
        if (retourHeureReprise === null){
          formData.append('retour_heure_reprise', "");
        }
        else{
          formData.append('retour_heure_reprise', retourHeureReprise);
        }
      }
      if (singlePrice === null){
        formData.append('single_price', 0);
      }
      else{
        formData.append('single_price', singlePrice);
      }
      if (doublePrice === null){
        formData.append('double_price', 0);
      }
      else{
        formData.append('double_price', doublePrice);
      }
      if (triplePrice === null){
        formData.append('triple_price', 0);
      }
      else{
        formData.append('triple_price', triplePrice);
      }
      if (quadPrice === null){
        formData.append('quad_price', 0);
      }
      else{
        formData.append('quad_price', quadPrice);
      }

      formData.append('petit_dejeuner', isPetitDejChecked);
      formData.append('dejeuner', isDejChecked);
      formData.append('dinner', isDinnerChecked);
  
      try {
        const response = await axios.patch(`https://e.amctravel.net/omrahajjpacks/${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          navigate('/omra-hajj-packs');
          toast.success("Pack mis à jour avec succès !");
        } else {
          console.error('Failed to update pack');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    return (
      <div className='container'>
    <ToastContainer />
    <form onSubmit={handleFormSubmit}>
      <div className='row mb-4'>
        <div className='col'>
          <button
            className='btn btn-danger'
            onClick={() => navigate('/omra-hajj-packs')}
          >
            Retour
          </button>
        </div>
        <div className='col d-flex justify-content-center'>
          <h4 className='text-center mt-1'>Informations pack</h4>
        </div>
        <div className='col'></div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='label'>Libellé du pack</label>
            <input
              type='text'
              className='form-control'
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              id='label'
              placeholder='Libellé'
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='typePack'>Type du pack</label>
            <Select
              className='w-100 text-center'
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              
            >
              <MenuItem value=''>-- Select --</MenuItem>
              <MenuItem value='omra'>Omra</MenuItem>
              <MenuItem value='hajj'>Hajj</MenuItem>
            </Select>
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='img-pack'>Image</label>
            <input
              type='file'
              className='form-control-file'
              id='img-pack'
              onChange={handlePackImageChange}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='dateAller'>Date aller</label>
            <input
              type='date'
              value={dateAller}
              onChange={(e) => setDateAller(e.target.value)}
              className='form-control'
              id='dateAller'
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='dateRetour'>Date retour</label>
            <input
              type='date'
              value={dateRetour}
              onChange={(e) => setDateRetour(e.target.value)}
              className='form-control'
              id='dateRetour'
              
            />
          </div>
        </div>
      </div>
      <hr />
      <h5 className='text-center'>Hotel Médine</h5>
      <div className='row'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='hotel'>Hôtel</label><br />
            <Select
              className='w-100 text-center'
              value={hotelMedina}
              onChange={(e) => handleHotelMadinah(e)}
              id='hotel'
              displayEmpty
              
            >
              <MenuItem value=''>-- Select --</MenuItem>
              <MenuItem value='Millennium al Aqeeq 5*'>
                Millennium al Aqeeq 5*
              </MenuItem>
              <MenuItem value='Madinah Concorde hotel 3*'>
                Madinah Concorde hotel 3*
              </MenuItem>
              <MenuItem value='Nusuk Al Madinah hotel 3.5*'>
                Nusuk Al Madinah hotel 3.5*
              </MenuItem>
              <MenuItem value="Un hôtel exceptionnel">Un hôtel exceptionnel</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
            <div class="form-check">
              <input class="form-check-input" checked={isAutreMadinahChecked} onChange={(e) => setIsAutreMadinahChecked(e.target.checked)} type="checkbox" id="autreMadina"/>
              <label class="form-check-label" for="autreMadina">
                Autre
              </label>
            </div>
              {
                isAutreMadinahChecked && (
                  <div className='form-group'>
                      <input type="text" class="form-control" placeholder="Saisir le nom de l'hôtel" id="" value={hotelMedina} onChange={(e) => setHotelMedina(e.target.value)}/>
                  </div>
                )
              }
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='checkin-medina'>Check-in</label>
            <input
              type='date'
              className='form-control'
              id='checkin-medina'
              value={checkInHM}
              onChange={(e) => setCheckInHM(e.target.value)}
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='checkout-medina'>Check-out</label>
            <input
              type='date'
              className='form-control'
              value={checkOutHM}
              onChange={(e) => setCheckOutHM(e.target.value)}
              id='checkout-medina'
              
            />
          </div>
        </div>
      </div>

      <hr />
      <h5 className='text-center'>Hotel Makkah</h5>
      <div className='row'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='hotelMakkah'>Hôtel</label><br />
            <Select
              className='w-100 text-center'
              id='hotelMakkah'
              value={hotelMakkah}
              onChange={(e) => handleHotelMakkah(e)}
              displayEmpty
              
            >
              <MenuItem value=''>-- Select --</MenuItem>
              <MenuItem value='Hotel Marriott 5*'>Hotel Marriott 5*</MenuItem>
              <MenuItem value='Hilton Suit 5*'>Hilton Suit 5*</MenuItem>
              <MenuItem value='Conrad 5*'>Conrad 5*</MenuItem>
              <MenuItem value='Hotel Sheraton 4*'>Hotel Sheraton 4*</MenuItem>
              <MenuItem value="Un hôtel exceptionnel">Un hôtel exceptionnel</MenuItem>
              <MenuItem value='Autre'>Autre</MenuItem>
            </Select>
            <div class="form-check">
              <input class="form-check-input" checked={isAutreMakkahChecked} onChange={(e) => setIsAutreMakkahChecked(e.target.checked)} type="checkbox" id="autreMakkah"/>
              <label class="form-check-label" for="autreMakkah">
                Autre
              </label>
            </div>
              {
                isAutreMakkahChecked && (
                  <div className='form-group'>
                      <input type="text" class="form-control" placeholder="Saisir le nom de l'hôtel" id="" value={hotelMakkah} onChange={(e) => setHotelMakkah(e.target.value)}/>
                  </div>
                )
              }
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='checkin-makkah'>Check-in</label>
            <input
              type='date'
              className='form-control'
              id='checkin-makkah'
              value={checkInHK}
              onChange={(e) => setCheckInHK(e.target.value)}
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='checkout-makkah'>Check-out</label>
            <input
              type='date'
              className='form-control'
              id='checkout-makkah'
              value={checkOutHK}
              onChange={(e) => setCheckOutHK(e.target.value)}
              
            />
          </div>
        </div>
      </div>

      <hr />
      <h5 className='text-center'>Informations vols</h5>
      <p><b>DÉPART :</b></p>
      <div className='row mt-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='aller-company'>Compagnies aériennes</label><br />
            <Select
              className='w-100 text-center'
              value={allerCompany}
              onChange={(e) => setAllerCompany(e.target.value)}
              id='aller-company'
              displayEmpty
              
            >
              <MenuItem value=''>-- Select --</MenuItem>
              <MenuItem value='SAUDIA AIRLINES'>SAUDIA AIRLINES</MenuItem>
              <MenuItem value='TURKISH AIRLINES'>TURKISH AIRLINES</MenuItem>
              <MenuItem value='ROYAL JORDANIAN'>ROYAL JORDANIAN</MenuItem>
              <MenuItem value='EgyptAir'>EgyptAir</MenuItem>
            </Select>
            <div class="form-check">
                    <input class="form-check-input" checked={isDepartVolChecked} onChange={(e) => setIsDepartVolChecked(e.target.checked)} type="checkbox" id="autreAllerVol"/>
                    <label class="form-check-label" for="autreAllerVol">
                      Autre
                    </label>
                  </div>
                  {
                    isDepartVolChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" placeholder="Saisir la Compagnie aérienne" id="" value={allerCompany} onChange={(e) => setAllerCompany(e.target.value)}/>
                      </div>
                    )
                  }      
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='villeAllerD'>Ville de départ</label>
            <input
              type='text'
              className='form-control'
              value={allerVilleDepart}
              onChange={(e) => setAllerVilleDepart(e.target.value)}
              id='villeAllerD'
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='villeAllerA'>Ville d'arrivée</label>
            <input
              type='text'
              className='form-control'
              value={allerVilleArrive}
              onChange={(e) => setAllerVilleArrive(e.target.value)}
              id='villeAllerA'
              
            />
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='allerDateDepart'>Date du départ</label>
            <input
              type='date'
              className='form-control'
              id='allerDateDepart'
              value={allerDateDepart}
              onChange={(e) => setAllerDateDepart(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='allerHeureDepart'>Heure du départ</label>
            <input
              type='time'
              className='form-control'
              id='allerHeureDepart'
              value={allerHeureDepart}
              onChange={(e) => setAllerHeureDepart(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='allerDateArrive'>Date d'arrivée</label>
            <input
              type='date'
              className='form-control'
              id='allerDateArrive'
              value={allerDateArrive}
              onChange={(e) => setAllerDateArrive(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='allerHeureArrive'>Heure d'arrivée</label>
            <input
              type='time'
              className='form-control'
              id='allerHeureArrive'
              value={allerHeureArrive}
              onChange={(e) => setAllerHeureArrive(e.target.value)}
              
            />
          </div>
        </div>
      </div>
      <div className='escale'>
        <div className='form-check'>
          <input
            className='form-check-input'
            checked={isEscaleAllerChecked}
            onChange={handleEscaleAllerChange}
            type='checkbox'
            id='allerEscale'
          />
          <label className='form-check-label' htmlFor='allerEscale'>
            Escale
          </label>
        </div>
      </div>
      {isEscaleAllerChecked && (
        <>
          <div className='row mt-2'>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='allerVilleEscale'>Ville d'escale</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder="Ville d'escale"
                  id='allerVilleEscale'
                  value={allerVilleEscale}
                  onChange={(e) => setAllerVilleEscale(e.target.value)}
                  
                />
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='allerDateEscale'>Date d'escale</label>
                <input
                  type='date'
                  className='form-control'
                  id='allerVilleEscale'
                  value={allerDateEscale}
                  onChange={(e) => setAllerDateEscale(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='allerHeureEscale'>Heure d'escale</label>
                <input
                  type='time'
                  className='form-control'
                  id='allerHeureEscale'
                  value={allerHeureEscale}
                  onChange={(e) => setAllerHeureEscale(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='allerRepriseVol'>Date du reprise de vol</label>
                <input
                  type='date'
                  className='form-control'
                  id='allerRepriseVol'
                  value={allerDateReprise}
                  onChange={(e) => setAllerDateReprise(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='allerHeureReprise'>
                  Heure du reprise de vol
                </label>
                <input
                  type='time'
                  className='form-control'
                  id='allerHeureReprise'
                  value={allerHeureReprise}
                  onChange={(e) => setAllerHeureReprise(e.target.value)}
                  
                />
              </div>
            </div>
          </div>
        </>
      )}
      <hr />
      <p><b>RETOUR :</b></p>
      <div className='row mt-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourCompany'>Compagnies aériennes</label><br />
            <Select
              id='retourCompany'
              className='w-100 text-center'
              value={retourCompany}
              onChange={(e) => setRetourCompany(e.target.value)}
              displayEmpty
              
            >
              <MenuItem value=''>-- Select --</MenuItem>
              <MenuItem value='SAUDIA AIRLINES'>SAUDIA AIRLINES</MenuItem>
              <MenuItem value='TURKISH AIRLINES'>TURKISH AIRLINES</MenuItem>
              <MenuItem value='ROYAL JORDANIAN'>ROYAL JORDANIAN</MenuItem>
              <MenuItem value='EgyptAir'>EgyptAir</MenuItem>
            </Select>

            <div class="form-check">
                    <input class="form-check-input" checked={isRetourVolChecked} onChange={(e) => setIsRetourVolChecked(e.target.checked)} type="checkbox" id="autreRetourVol"/>
                    <label class="form-check-label" for="autreRetourVol">
                      Autre
                    </label>
                  </div>
                  {
                    isRetourVolChecked && (
                      <div className='form-group'>
                          <input type="text" class="form-control" placeholder="Saisir la Compagnie aérienne" id="" value={retourCompany} onChange={(e) => setRetourCompany(e.target.value)}/>
                      </div>
                    )
                  }  
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourVilleDepart'>Ville de départ</label>
            <input
              type='text'
              className='form-control'
              id='retourVilleDepart'
              value={retourVilleDepart}
              onChange={(e) => setRetourVilleDepart(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourVilleArrive'>Ville d'arrivée</label>
            <input
              type='text'
              className='form-control'
              id='retourVilleArrive'
              value={retourVilleArrive}
              onChange={(e) => setRetourVilleArrive(e.target.value)}
              
            />
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourDateDepart'>Date du départ</label>
            <input
              type='date'
              className='form-control'
              id='retourDateDepart'
              value={retourDateDepart}
              onChange={(e) => setRetourDateDepart(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourHeureDepart'>Heure du départ</label>
            <input
              type='time'
              className='form-control'
              id='retourHeureDepart'
              value={retourHeureDepart}
              onChange={(e) => setRetourHeureDepart(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourDateArrive'>Date d'arrivée</label>
            <input
              type='date'
              className='form-control'
              id='retourDateArrive'
              value={retourDateArrive}
              onChange={(e) => setRetourDateArrive(e.target.value)}
              
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='retourHeureArrive'>Heure d'arrivée</label>
            <input
              type='time'
              className='form-control'
              id='retourHeureArrive'
              value={retourHeureArrive}
              onChange={(e) => setRetourHeureArrive(e.target.value)}
              
            />
          </div>
        </div>
      </div>
      <div className='escale'>
        <div className='form-check'>
          <input
            className='form-check-input'
            checked={isEscaleRetourChecked}
            onChange={handleEscaleRetourChange}
            type='checkbox'
            id='escaleRetour'
          />
          <label className='form-check-label' htmlFor='escaleRetour'>
            Escale
          </label>
        </div>
      </div>
      {isEscaleRetourChecked && (
        <>
          <div className='row mt-2'>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='retourVilleEscale'>Ville d'escale</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder="Ville d'escale"
                  id='retourVilleEscale'
                  value={retourVilleEscale}
                  onChange={(e) => setRetourVilleEscale(e.target.value)}
                  
                />
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='retourDateEscale'>Date d'escale</label>
                <input
                  type='date'
                  className='form-control'
                  id='retourDateEscale'
                  value={retourDateEscale}
                  onChange={(e) => setRetourDateEscale(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='retourHeureEscale'>Heure d'escale</label>
                <input
                  type='time'
                  className='form-control'
                  id='retourHeureEscale'
                  value={retourHeureEscale}
                  onChange={(e) => setRetourHeureEscale(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='retourDateReprise'>Date du reprise de vol</label>
                <input
                  type='date'
                  className='form-control'
                  id='retourDateReprise'
                  value={retourDateReprise}
                  onChange={(e) => setRetourDateReprise(e.target.value)}
                  
                />
              </div>
            </div>
            <div className='col'>
              <div className='form-group'>
                <label htmlFor='retourHeureReprise'>
                  Heure du reprise de vol
                </label>
                <input
                  type='time'
                  className='form-control'
                  id='retourHeureReprise'
                  value={retourHeureReprise}
                  onChange={(e) => setRetourHeureReprise(e.target.value)}
                  
                />
              </div>
            </div>
          </div>
        </>
      )}
      <hr />
      <h5>Tarifs (en €/personne)</h5>
      <div className='row mt-4'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='chambreSingle'>Chambre single</label>
            <input
              type='number'
              className='form-control'
              id='chambreSingle'
              value={singlePrice}
              onChange={(e) => setSinglePrice(e.target.value)}
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='chambreDouble'>Chambre double</label>
            <input
              type='number'
              className='form-control'
              id='chambreDouble'
              value={doublePrice}
              onChange={(e) => setDoublePrice(e.target.value)}
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='chambreTriple'>Chambre triple</label>
            <input
              type='number'
              className='form-control'
              id='chambreTriple'
              value={triplePrice}
              onChange={(e) => setTriplePrice(e.target.value)}
              
            />
          </div>
        </div>

        <div className='col'>
          <div className='form-group'>
            <label htmlFor='chambreQuadruple'>Chambre quadruple</label>
            <input
              type='number'
              className='form-control'
              id='chambreQuadruple'
              value={quadPrice}
              onChange={(e) => setQuadPrice(e.target.value)}
              
            />
          </div>
        </div>
      </div>
      
      <hr/>
          <h5>Repas (Cocher les repas inclus)</h5>
          <div className = "row mt-3">
            <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isPetitDejChecked} onChange={(e) => setIsPetitDejChecked(e.target.checked)} type="checkbox" id="petit-dej"/>
                <label class="form-check-label" for="petit-dej">
                  Petit déjeûner
                </label>
            </div>
            </div>
            <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isDejChecked} onChange={(e) => setIsDejChecked(e.target.checked)} type="checkbox" id="dejeuner"/>
                <label class="form-check-label" for="dejeuner">
                Déjeûner
                </label>
              </div>
          </div>
          <div className = 'col'>
              <div class="form-check">
                <input class="form-check-input" checked={isDinnerChecked} onChange={(e) => setIsDinnerChecked(e.target.checked)} type="checkbox" id="dinner"/>
                <label class="form-check-label" for="dinner">
                Dinner
                </label>
              </div>
          </div>
          </div>

      <div className='form-group text-center mt-4'>
        <button type='submit' className='btn btn-primary'>
          Modifier 
        </button>
      </div>
    </form>
  </div>

    )
  }
