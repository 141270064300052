import React, { useState, useEffect}from 'react'
import Navbar from '../components/Navbar';
import '../style/detailsOmra.css';
import Mosque from '../icons/mosque.png';
import Kaabah from '../icons/makkah.png';
import Learning from '../icons/idea.png';
import PlaneUp from '../icons/planeUp.png';
import PlaneDown from '../icons/planeDown.png';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import axios from 'axios';
import Footer from '../components/Footer';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from '../icons/Avatar.png';

export const DetailsHajj = () => {
  const { id } = useParams();
  const [pack, setPack] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://e.amctravel.net/omrahajjpacks/${id}/`);
        if (response.status === 200) {
            setPack(response.data);
        }
      } catch(error){
        console.error(error);
      }
    }
    fetchData();
    }, [id])
    
    let MadinaSrc;

    if (pack.hotel_medina === 'Millennium al Aqeeq 5*') {
      MadinaSrc = require('../images/MadinahAlaqeeq.jpg');
    } else if (pack.hotel_medina === 'Madinah Concorde hotel 3*') {
      MadinaSrc = require('../images/MadinahConcorde.jpg');
    } else if (pack.hotel_medina === 'Nusuk Al Madinah hotel 3.5*') {
      MadinaSrc = require('../images/MadinahNusuk.jpg');
    }
    else{
      MadinaSrc = require('../images/defaultHotel.jpg');
    }

    let MakkahSrc;
    if (pack.hotel_makkah === 'Hotel Marriott 5*') {
        MakkahSrc = require('../images/MakkahMarriott.jpg');
      } else if (pack.hotel_makkah === 'Hilton Suit 5*') {
        MakkahSrc = require('../images/MakkahHilton.jpg');
      } else if (pack.hotel_makkah === 'Conrad 5*') {
        MakkahSrc = require('../images/MakkahConrad.jpg');
      } else if (pack.hotel_makkah === 'Hotel Sheraton 4*') {
        MakkahSrc = require('../images/MakkahSheraton.jpg');
      }
      else{
        MakkahSrc = require('../images/defaultHotel.jpg');
      }

      let companyAller;
      if (pack.aller_company === 'SAUDIA AIRLINES') {
          companyAller = require('../images/AirSaudi.png');
        } else if (pack.aller_company === 'TURKISH AIRLINES') {
          companyAller = require('../images/TurkishAir.png');
        } else if (pack.aller_company === 'ROYAL JORDANIAN') {
          companyAller = require('../images/AirJordan.png');
        } else if (pack.aller_company === 'EGYPT AIR') {
          companyAller = require('../images/AirEgypt.png');
        }
        else{
          companyAller = require('../images/defaultCompany.png');
        }

        let companyRetour;
        if (pack.retour_company === 'SAUDIA AIRLINES') {
            companyRetour = require('../images/AirSaudi.png');
          } else if (pack.retour_company === 'TURKISH AIRLINES') {
            companyRetour = require('../images/TurkishAir.png');
          } else if (pack.retour_company === 'ROYAL JORDANIAN') {
            companyRetour = require('../images/AirJordan.png');
          } else if (pack.retour_company === 'EGYPT AIR') {
            companyRetour = require('../images/AirEgypt.png');
          }
          else{
            companyRetour = require('../images/defaultCompany.png');
          }

      const checkInDate = new Date(pack.check_in_hk);
const checkOutDate = new Date(pack.check_out_hk);

const timeDifference = checkOutDate.getTime() - checkInDate.getTime();
const numberOfNightsMakkah = Math.ceil(timeDifference / (1000 * 3600 * 24))

const checkInDateMd = new Date(pack.check_in_hm);
const checkOutDateMd = new Date(pack.check_out_hm);

const timeDifference2 = checkOutDateMd.getTime() - checkInDateMd.getTime();
const numberOfNightsMadina = Math.ceil(timeDifference2 / (1000 * 3600 * 24))
const moisFrancais = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
        const navigate = useNavigate();
  return (
    <>  
        <div className='nav-cont'>
            <Navbar/>
            <FloatingWhatsApp
        phoneNumber="+33744192665" 
        accountName="AMC Travels" 
        avatar={Avatar}
        statusMessage="Vous recevrez une réponse dans moins de 24 heures."
        chatMessage="Cher client, merci de nous contacter" 
        styles={{
          left: '10px',
          bottom: '10px',
          zIndex: 100,
        }}
      />
        </div>
        {Object.keys(pack).length > 0 && (
        <>
        {/* <div className='small-nav w-100 d-flex justify-content-center'>
            <span className='nav-element'>Description</span>
            <span className='nav-element'>Hôtels</span>
            <span className='nav-element'>Vols</span>
            <span className='nav-element'>Tarifs</span>
            <span className='nav-element'>Documents à fournir</span>
        </div> */}

        <div className='container description ' id="description">
            <h3 className='heading-class-blue w-100 text-center'>{pack.label}</h3>
            <div className='row mt-4'>
  <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center'>
    <div className='description-element w-100 text-center '>
      <img className="description-icon mt-3" src={Learning} alt=""/>
      <p className='mt-2 para-class-blue-bold'>Préparation aux rites du Hajj</p>
      <p className='mt-2 para-class-blue  text-center'>Des cours seront dispensés et un guide sera avec vous tout au long de votre séjour pour accomplir votre Hajj dans les meilleures conditions.</p>
    </div>
  </div>

  <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center'>
    <div className='description-element w-100 text-center '>
      <img className="description-icon mt-3" src={Kaabah} alt=""/>
      <p className='mt-2 para-class-blue-bold'>Visite guidée à Médine & Hajj à La Mecque</p>
      <p className='mt-2 para-class-blue  text-center'>Le séjour comprend des visites guidées à Médine et à La Mecque riches en enseignements. Le Hajj sera encadré et se fera en groupe avec votre guide.</p>
    </div>
  </div>

  <div className='col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center'>
    <div className='description-element w-100 text-center '>
      <img className="description-icon mt-3" src={Mosque} alt=""/>
      <p className='mt-2 para-class-blue-bold'>Hôtel proche des mosquées sacrées</p>
      <p className='mt-2 para-class-blue  text-center'>Hôtel proche des 2 mosquées sacrées. Vous logerez à Médine dans un hôtel à 300m et à La Mecque à 100m, en face.</p>
    </div>
  </div>
</div>

            <div className='row mt-5'>
                <div className='col d-flex justify-content-center'>
                    <button className='btn btn-reserver btn-orange' onClick={() => navigate(`/reservation-omra-hajj/${pack.id}`)}>Réserver</button>
                </div>
            </div>
        </div>

        <div className='row hotel ' id="hotel">
            <h2 className='text-white mb-4 w-100 text-center'><b>HÔTELS</b></h2>
            <div className='container text-center mb-5'>
            <div className='row mt-4'>
            <div className='col-lg-6 col-md-12 col-sm-12 hotel-medina'>
                <h3 className='text-white ml-3'>Médine :</h3>
                <h3 className='text-white ml-3 '>{pack.hotel_medina}</h3>
                <img className="hotel-image " src={MadinaSrc}/>
                <div className='details-hotel mx-auto'>
                    <div className=''><svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
  <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg>Check-in : {moment(pack.check_in_hm).format('DD/MM/YYYY')}</div>
                    <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16">
  <path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg>Check-out : {moment(pack.check_out_hm).format('DD/MM/YYYY')}</div>
                    <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>Durée : {numberOfNightsMadina} nuitées</div>
                    {/* <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-signpost" viewBox="0 0 16 16">
  <path d="M7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414a1 1 0 0 0-2 0zM12.532 5l1.666 2-1.666 2H2V5h10.532z"/>
</svg>Distance : 300m</div> */}
                </div>

            </div>

            <div className='col-lg-6 col-md-12 col-sm-12 hotel-makka'>
            <h3 className='text-white'>Makkah :</h3>
                <h3 className='text-white'>{pack.hotel_makkah}</h3>
                <img className="hotel-image" src={MakkahSrc}/>
                <div className='details-hotel mx-auto'>
                    <div className=''><svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
  <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg>Check-in : {moment(pack.check_in_hk).format('DD/MM/YYYY')}</div>
                    <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16">
  <path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg>Check-out : {moment(pack.check_out_hk).format('DD/MM/YYYY')}</div>
                    <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>Durée : {numberOfNightsMakkah} nuitées</div>
                    {/* <div><svg xmlns="http://www.w3.org/2000/svg"  style={{marginBottom:'5px', marginRight:'15px'}} width="30 " height="30 " fill="currentColor" class="bi bi-signpost" viewBox="0 0 16 16">
  <path d="M7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414a1 1 0 0 0-2 0zM12.532 5l1.666 2-1.666 2H2V5h10.532z"/>
</svg>Distance : 300m</div> */}
                </div>
            </div>
            </div>
            </div>

 
        </div>

        <div className='container vols ' id="vols" style={{maxWidth:'1300px'}}>
            <h3 className='heading-class-blue mb-4 w-100 text-center'>Vols</h3>
            
            <div className='row vol-aller mt-4 mb-5'>
                <div className='col'>
                    <div className='vol-element'>
                        <div className='row'>
                            <div className='col-lg-2 col-md-6 col-sm-12 d-flex border-right' >
                                 
                                <p className='para-class-blue ml-3 mx-auto para-vol marginB'>   
                                    <b>DÉPART</b><br/>{pack.aller_ville_depart} - {pack.aller_ville_arrive}
                                </p>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12 mx-auto text-center marginB'>
                                <p className='para-class-blue para-vol para-company'><img className='mr-2 company' src={companyAller}/><b>{pack.aller_company}</b></p>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-12 d-flex justify-content-center'>
                                <img className='mr-3 icon' src={require('../icons/planeUp.png')}/><p className='para-class-blue para-vol'><b>
                                   {(() => {
                                let departAller = new Date(pack.aller_date_depart);
                                const time = new Date(`2000-01-01T${pack.aller_heure_depart}`);
                                let departHeure = time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

                             return `${departAller.getDate()} ${moisFrancais[departAller.getMonth()]}. À ${departHeure}`;
                })()}
                                    </b><br/>{pack.aller_ville_depart}</p>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center'>

                            {pack.escale_aller ? (
  <>
    <div className="dashed-line  marginD"></div>
    <p className="position-absolute text-center escale-text para-vol marginO" style={{ }}>
      <b>
        {(() => {
          const time1 = new Date(`2000-01-01T${pack.aller_heure_escale}`);
          let EscaleHeure = time1.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
          const time2 = new Date(`2000-01-01T${pack.aller_heure_reprise}`);
          let RepriseHeure = time2.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
          return `Escale À ${pack.aller_ville_escale} de ${EscaleHeure} À ${RepriseHeure}`;
        })()}
      </b>
    </p>
  </>
) : (
    <>
    <div class="dashed-line marginD"></div>
    <p className="position-absolute text-center para-vol direct-vol" style={{}}><b>Vol Direct</b></p>
    </>
)}
                                
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-12 d-flex justify-content-center'>
                                <img className='mr-3 icon' src={require('../icons/planeDown.png')}/><p className='para-class-blue para-vol'><b>
                                {(() => {
                                let arrAller = new Date(pack.aller_date_arrive);
                                const time = new Date(`2000-01-01T${pack.aller_heure_arrive}`);
                                let arriveHeure = time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

                             return `${arrAller.getDate()} ${moisFrancais[arrAller.getMonth()]}. À ${arriveHeure}`;
                })()}
                                    </b><br/>{pack.aller_ville_arrive}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row vol-retour mt-3 '>
                <div className='col'>
                    <div className='vol-element'>
                        <div className='row'>
                            <div className='col-lg-2 col-md-6 col-sm-12 d-flex border-right' >
                               
                                <p className='para-class-blue ml-3 mx-auto para-vol marginB'>   
                                    <b>RETOUR</b><br/>{pack.retour_ville_depart} - {pack.retour_ville_arrive}
                                </p>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12 mx-auto text-center marginB'>
                                <p className='para-class-blue para-company para-vol'><img className='mr-2 company' src={companyRetour}/><b>{pack.retour_company}</b></p>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-12 d-flex justify-content-center'>
                                <img className='mr-3 icon' src={require('../icons/planeUp.png')}/><p className='para-class-blue para-vol'><b>
                                {(() => {
                                let departRetour = new Date(pack.retour_date_depart);
                                const time = new Date(`2000-01-01T${pack.retour_heure_depart}`);
                                let departHeure = time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

                             return `${departRetour.getDate()} ${moisFrancais[departRetour.getMonth()]}. À ${departHeure}`;
                })()}
                                    </b><br/>{pack.retour_ville_depart}
                                </p>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center'>
                            {pack.escale_retour ? (
  <>
    <div className="dashed-line marginD"></div>
    <p className="position-absolute text-center escale-text para-vol marginO" style={{ }}>
      <b>
        {(() => {
          const time1 = new Date(`2000-01-01T${pack.retour_heure_escale}`);
          let EscaleHeure = time1.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
          const time2 = new Date(`2000-01-01T${pack.retour_heure_reprise}`);
          let RepriseHeure = time2.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
          return `Escale À ${pack.retour_ville_escale} de ${EscaleHeure} À ${RepriseHeure}`;
        })()}
      </b>
    </p>
  </>
) : (
    <>
    <div class="dashed-line marginD"></div>
    <p className="position-absolute text-center para-vol direct-vol" style={{}}><b>Vol Direct</b></p>
    </>
)}
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-12 d-flex justify-content-center'>
                                <img className='mr-3 icon' src={require('../icons/planeDown.png')}/><p className='para-class-blue para-vol'><b>{(() => {
                                let arriveRetour = new Date(pack.retour_date_arrive);
                                const time = new Date(`2000-01-01T${pack.retour_heure_arrive}`);
                                let arriveHeure = time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });

                             return `${arriveRetour.getDate()} ${moisFrancais[arriveRetour.getMonth()]}. À ${arriveHeure}`;
                })()}
                                    </b><br/>{pack.retour_ville_arrive}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col d-flex justify-content-center'>
                    <button className='btn btn-reserver btn-orange' onClick={() => navigate(`/reservation-omra-hajj/${pack.id}`)}>Réserver</button>
                </div>
            </div>
        </div>
        
        <div className='container tarifs' id="tarifs" style={{maxWidth:'1300px'}}>
            <h2 className='heading-class-gold w-100 text-center'>Tarifs</h2>
            <div className='row'>
              {Math.floor(pack.quad_price) !== 0 && (
                <div className='col-lg-3 col-md-6 col-sm-12 mt-4 mx-auto' >
                    <div className="tarif-element">
                    <div className='d-flex justify-content-center'>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                    </div>
                    <h5 className='mt-3 mb-3 text-center heading-class-gold'>Chambre quadruple</h5>
                    <h3 className='w-100 text-center tarif-price'>{Math.floor(pack.quad_price)}€/<span style={{fontSize:'20px'}}><b>personne</b></span></h3>
                    </div>
                </div>
              )}
                
              {Math.floor(pack.triple_price) !== 0 && (

                <div className='col-lg-3 col-md-6 col-sm-12 mt-4 mx-auto'>
                    <div className="tarif-element">
                    <div className='d-flex justify-content-center'>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                    </div>
                    <h5 className='mt-3 mb-3 text-center heading-class-gold'>Chambre triple</h5>
                    <h3 className='w-100 text-center tarif-price'>{Math.floor(pack.triple_price)}€/<span style={{fontSize:'20px'}}><b>personne</b></span></h3>
                    </div>
                </div>
              )}

              {Math.floor(pack.double_price) !== 0 && (
                <div className='col-lg-3 col-md-6 col-sm-12 mt-4 mx-auto'>
                    <div className="tarif-element">
                    <div className='d-flex justify-content-center'>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/> 
                    </div>
                    <h5 className='mt-3 mb-3 text-center heading-class-gold'>Chambre double</h5>
                    <h3 className='w-100 text-center tarif-price'>{Math.floor(pack.double_price)}€/<span style={{fontSize:'20px'}}><b>personne</b></span></h3>
                    </div>
                </div>
              )}

              {Math.floor(pack.single_price) !== 0 && (
                <div className='col-lg-3 col-md-6 col-sm-12 mt-4 mx-auto'>
                    <div className="tarif-element">
                    <div className='d-flex justify-content-center'>
                        <img className='tarif-icon' src={require('../icons/single-bed.png')}/>
                    </div>
                    <h5 className='mt-3 mb-3 text-center heading-class-gold'>Chambre Single</h5>
                    <h3 className='w-100 text-center tarif-price'>{Math.floor(pack.single_price)}€/<span style={{fontSize:'20px'}}><b>personne</b></span></h3>
                    </div>
                </div>
              )}
            </div>
            <div className='row mt-5'>
                <div className='col d-flex justify-content-center'>
                    <button className='btn btn-reserver' style={{background:'#573D00', color:'white'}} onClick={() => navigate(`/reservation-omra-hajj/${pack.id}`)}>Réserver</button>
                </div>
            </div>
        </div>
        <div className='container' id="" style={{maxWidth:'1300px'}}>
          <h3 className='heading-class-blue mb-4 w-100 text-center'>Médine :</h3>
          <div className='row'>
              <div className="col-8">
                  
              </div>
          </div>
        </div>

       
        </>   
        )}

        <Footer/>
    </>
  )
}

export default DetailsHajj;