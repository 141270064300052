import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import DetailsOmra from './pages/DetailsOmra';
import DetailsHajj from './pages/DetailsHajj';
import Sidebar from './components/Sidebar';
import GestionOHPack from './adminPages/GestionOHPack';
import { BrowserRouter as Router, Link, NavLink, Routes, Route,useLocation } from 'react-router-dom';
import ReservationHomeOH from './pages/ReservationHomeOH';
import ReservationHomeSimple from './pages/ReservationHomeSimple';
import OmraPage from './pages/OmraPage'; // Correct import
import HajjPage from './pages/HajjPage'; // Correct import
import Login from './pages/Login';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import axios from 'axios';

function App() {


  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/omra" element={<OmraPage />} />
        <Route path="/hajj" element={<HajjPage />} />
        <Route path="/access-admin-panel" element={<Login />} />
        <Route path="/details/:id" element={<DetailsOmra />} />
        <Route path="/details-hajj/:id" element={<DetailsHajj />} />
        <Route path="/reservation-omra-hajj/:id" element={<ReservationHomeOH />} />
        <Route path="/reservation-simple/:id" element={<ReservationHomeSimple />} />
      </Routes> 
        {localStorage.getItem('admin') && (
        <Routes>
            <Route path="/admin-panel" element={<Sidebar />} />
            <Route path="/simple-packs" element={<Sidebar />} />
            <Route path="/omra-hajj-packs" element={<Sidebar />} />
            <Route path="/simple-reservation" element={<Sidebar />} />
            <Route path="/reservation" element={<Sidebar />} />
            <Route path="/omra-hajj-reservation" element={<Sidebar />} />

            <Route path="/add-pack" element={<Sidebar />} />
            <Route path="/add-simple-pack" element={<Sidebar />} />
            <Route path="/edit-simple-pack/:id" element={<Sidebar />} />
            <Route path="/edit-oh-pack/:id" element={<Sidebar />} />
            <Route path="/details-oh-reservation/:id" element={<Sidebar />} />
            <Route path="/details-simple-reservation/:id" element={<Sidebar />} />
        </Routes> 
        )}
    </Router>
  );
}

export default App;



