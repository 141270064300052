import React , {useState,useEffect, useRef, useContext} from 'react'
import axios from 'axios'
import '../style/login.css'
import { toast , ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/Footer';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [csrfToken, setCsrfToken] = useState('');

const handleLogin = (event) => {
    event.preventDefault();

    // Create a login request object with the form data
    const loginData = {
      email: email,
      password: password
    };
    
    
    if( email === "contacts@am-bc.fr" && password === "Hassan2212"){
        localStorage.setItem('admin', true);
        window.location.href = '/admin-panel';
    }
    else if (email === "" && password === ""){
        toast.warning("Veuillez remplir les informations de connexion");
    }
    else{
        toast.error("Échec de la connexion, l'email ou le mot de passe est incorrect");
    }
    
};
  
  return (
    <>
    <div className="container2" >
        <ToastContainer/>
        <div className="top"></div>
        <div className="bottom"></div>
        <div className="center">
        <h2>AMC ADMIN</h2>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" />
            <button className="login-btn" type="submit" onClick={handleLogin}>Se connecter</button>
            <h2>&nbsp;</h2>
        </div>
    </div>
    </>
  )
}

export default Login;