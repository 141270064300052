import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../style/CarouselPacks.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import PackCarouselItem from "./PackCarouselItem"; // Import the PackCarouselItem component
import { useNavigate } from "react-router-dom";

const CarouselPacks = ({ packs }) => {
  const navigate = useNavigate();
  const [slidesPerView, setSlidesPerView] = useState(2); // Default to 2 slides per view

  useEffect(() => {
    // Check the device width and set slidesPerView accordingly
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setSlidesPerView(1); // For mobile devices
      } else {
        setSlidesPerView(2); // For other devices
      }
    };

    // Initial call to set slidesPerView
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        spaceBetween={50}
        slidesPerView={slidesPerView} // Use dynamic slidesPerView
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {packs.map((pack, index) => (
          <SwiperSlide
            key={index}
            className="swiper-fixed-height-100 swiper-fixed-width-100"
          >
            <PackCarouselItem pack={pack} navigate={navigate} />
          </SwiperSlide>
        ))}
        <div style={{ height: "50px" }}></div>

        <div className="mt-3 mb-3 slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>{" "}
    </div>
  );
};

export default CarouselPacks;
