import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Avatar from "../icons/Avatar.png";
import HajjInformation from "../components/HajjInformation";
import CarouselPacks from "../components/CarouselPacks";
import { api } from "../utils/constants";
import '../style/detailsOmra.css';

const HajjPage = () => {
  const [packs, setPacks] = useState([]);

  useEffect(() => {
    api
      .get("omrahajjpacks/")
      .then((response) => {
        // Filter packs with type === "hajj"
        const hajjPacks = response.data.filter((pack) => pack.type === "hajj");
        // Reverse the order of packs based on their IDs
        const reversedPacks = hajjPacks.reverse();
        setPacks(reversedPacks);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const chatMessage = "Vous recevrez une réponse dans moins de 24 heures.";

  return (
    <div>
      <div className='nav-cont'>
        <Navbar />
      </div>
      <div className="row mt-5 mb-4" id="hajjOmra">
        <h3 className="w-100 text-center packs-title mb-4">
          Nos Packs HAJJ{" "}
          <h5 className="packs-subtitle mt-2">
            Découvrez nos packs HAJJ toute l'année !
          </h5>
        </h3>
      </div>

      <div
        style={{
          // backgroundImage: `url(${OmraImg1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Render the CarouselPacks component and pass the packs as props */}
        <CarouselPacks packs={packs} />
      </div>

      <HajjInformation />

      {/* Floating WhatsApp button */}
      <FloatingWhatsApp
        phoneNumber="+33744192665"
        accountName="AMC Travels"
        avatar={Avatar}
        statusMessage={chatMessage}
        chatMessage={chatMessage}
        styles={{
          left: "10px",
          bottom: "10px",
          zIndex: 100,
          width: "500px",
        }}
      />
      <span id="contact-us">
        <Footer />
      </span>
    </div>
  );
};

export default HajjPage;
