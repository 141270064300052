import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Link,
  useNavigate,
  useLocation,
  useNavigateeParams,
} from "react-router-dom";
import SelectSearch from "react-select";
import { Select, MenuItem } from "@material-ui/core";
import { api } from "../utils/constants";

export const AddSimple = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [Image, setImage] = useState(null);
  const [destination, setDestination] = useState("");
  const [dateAller, setDateAller] = useState("");
  const [dateRetour, setDateRetour] = useState("");
  const [price, setPrice] = useState("");
  const [company, setCompany] = useState("");
  const [isPetitDejChecked, setIsPetitDejChecked] = useState(false);
  const [isDejChecked, setIsDejChecked] = useState(false);
  const [isDinnerChecked, setIsDinnerChecked] = useState(false);

  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countriesInFrench = response.data.map((country) => ({
          label: country.translations.fra.common,
          value: country.translations.fra.common,
        }));
        setCountries(countriesInFrench);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const handleChange = (selectedOption) => {
    setDestination(selectedOption);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    const formData = new FormData();
    formData.append("label", label);
    formData.append("image", Image);
    formData.append("date_aller", dateAller);
    formData.append("date_retour", dateRetour);
    formData.append("destination", destination.value);
    formData.append("company", company);
    formData.append("price", price);
    formData.append("petit_dejeuner", isPetitDejChecked);
    formData.append("dejeuner", isDejChecked);
    formData.append("dinner", isDinnerChecked);

    try {
      const response = await api.post("simplepacks/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201 || response.status === 200) {
        navigate("/simple-packs");
        toast.success("Pack ajouté avec succès !");
        setLabel("");
        setImage(null);
        setDateAller("");
        setDateRetour("");
        setCompany("");
        setPrice("");
        setIsPetitDejChecked(false);
        setIsDejChecked(false);
        setIsDinnerChecked(false);
        setLoading(false);
      } else {
        setLoading(false);
        setErrorMessage(
          "Une erreur s'est produite lors de l'ajout de pack'. Veuillez réessayer."
        );
        console.error("Failed to add pack");
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        "Une erreur s'est produite lors de l'ajout de pack'. Veuillez réessayer."
      );
      console.error("An error occurred:", error);
    }
  };

  const handlePackImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="container">
      <ToastContainer />
      <form onSubmit={handleFormSubmit}>
        <div className="row mb-4">
          <div className="col">
            <button
              className="btn btn-danger"
              onClick={() => navigate("/simple-packs")}
            >
              Retour
            </button>
          </div>
          <div className="col d-flex justify-content-center">
            <h4 className="text-center mt-1">Informations pack</h4>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col">
            <div class="form-group">
              <label for="label">Libellé du pack</label>
              <input
                type="text"
                class="form-control"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                id="label"
                placeholder="Libellé"
                required
              />
            </div>
          </div>
          <div className="col">
            <div class="form-group">
              <label for="typePack">Pays destination</label>
              <SelectSearch
                options={countries}
                search={true}
                value={destination}
                onChange={(e) => setDestination(e)}
                name="countries"
                placeholder="Choisr la destination"
                required
              />
            </div>
          </div>
          <div className="col">
            <div class="form-group">
              <label for="img-pack">Image Pack</label>
              <input
                type="file"
                class="form-control-file"
                id="img-pack"
                onChange={handlePackImageChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="company">Compagnies aériennes</label>
              <br />
              <Select
                className="w-100 text-center"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                id="company"
                displayEmpty
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                <MenuItem value="SAUDIA AIRLINES">SAUDIA AIRLINES</MenuItem>
                <MenuItem value="TURKISH AIRLINES">TURKISH AIRLINES</MenuItem>
                <MenuItem value="ROYAL JORDANIAN">ROYAL JORDANIAN</MenuItem>
                <MenuItem value="EgyptAir">EgyptAir</MenuItem>
              </Select>
            </div>
          </div>
          <div className="col">
            <div class="form-group">
              <label for="dateAller">Date aller</label>
              <input
                type="date"
                value={dateAller}
                onChange={(e) => setDateAller(e.target.value)}
                class="form-control"
                id="dateAller"
                required
              />
            </div>
          </div>

          <div className="col">
            <div class="form-group">
              <label for="dateRetour">Date retour</label>
              <input
                type="date"
                value={dateRetour}
                onChange={(e) => setDateRetour(e.target.value)}
                class="form-control"
                id="dateRetour"
                required
              />
            </div>
          </div>
        </div>
        <h5>Tarifs (en €/personne)</h5>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <input
                type="number"
                class="form-control"
                id="chambreSingle"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <h5 className="mt-2">Repas (Cocher les repas inclus)</h5>
        <div className="row mt-3 ">
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input"
                checked={isPetitDejChecked}
                onChange={(e) => setIsPetitDejChecked(e.target.checked)}
                type="checkbox"
                id="petit-dej"
              />
              <label class="form-check-label" for="petit-dej">
                Petit déjeûner
              </label>
            </div>
          </div>
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input"
                checked={isDejChecked}
                onChange={(e) => setIsDejChecked(e.target.checked)}
                type="checkbox"
                id="dejeuner"
              />
              <label class="form-check-label" for="dejeuner">
                Déjeûner
              </label>
            </div>
          </div>
          <div className="col">
            <div class="form-check">
              <input
                class="form-check-input"
                checked={isDinnerChecked}
                onChange={(e) => setIsDinnerChecked(e.target.checked)}
                type="checkbox"
                id="dinner"
              />
              <label class="form-check-label" for="dinner">
                Dinner
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-3">
          <div className="col d-flex justify-content-center">
            <button
              type="submit"
              className="btn w-100 btn-primary"
              disabled={loading}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
              Confirmer et ajouter le pack
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col w-100">
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSimple;
