import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import '../style/statistics.css';

export const Statistics = () => {
    const [visitorCount, setVisitorCount] = useState(0);
    const [reservationsCount, setReservationsCount] = useState(0);
    const [packsCount, setPacksCount] = useState(0);

    useEffect(() => {
    const getVisitorCount = async () => {
        try {
            const response = await axios.get('https://e.amctravel.net/visitor-count/');
            const count = response.data.visitor_count;
            setVisitorCount(count);
        } catch (error) {
            console.error('Failed to fetch visitor count:', error);
        }
    };
    getVisitorCount();

    const fetchData = async (apiUrl) => {
        try {
          const response = await axios.get(apiUrl);
          return response.data; // Assuming the response contains an array of records
        } catch (error) {
          console.error(`Failed to fetch data from ${apiUrl}:`, error);
          return []; // Return an empty array in case of an error
        }
      };
      
      // Define the API URLs
      const customReservationsApiUrl = 'https://e.amctravel.net/customreservations/';
      const simpleReservationsApiUrl = 'https://e.amctravel.net/simplereservations/';
      const ohReservationsApiUrl = 'https://e.amctravel.net/ohreservations/';
      
      const ohPacksApiUrl = 'https://e.amctravel.net/omrahajjpacks/';
      const simplePacksApiUrl = 'https://e.amctravel.net/simplepacks/';

      // Fetch data from the APIs
      const fetchCustom = fetchData(customReservationsApiUrl);
      const fetchSimpleReservations = fetchData(simpleReservationsApiUrl);
      const fetchOhReservations = fetchData(ohReservationsApiUrl);
      
      const fetchOhPacks = fetchData(ohPacksApiUrl);
      const fetchSimplePacks = fetchData(simplePacksApiUrl);

      // Use Promise.all to wait for all API requests to complete
      Promise.all([fetchCustom, fetchSimpleReservations, fetchOhReservations])
        .then((responses) => {
          // Calculate the total number of records across all APIs
          const totalRecords = responses.reduce(
            (accumulator, responseData) => accumulator + responseData.length,
            0
          );
      
          // Store the total number of records in the reservations_count variable
          setReservationsCount(totalRecords);
      
          // Now you can use the reservations_count variable as needed
        })
        .catch((error) => {
          console.error('Error fetching data from APIs:', error);
        });

        Promise.all([fetchOhPacks, fetchSimplePacks])
        .then((responses) => {
          // Calculate the total number of records across all APIs
          const totalRecords = responses.reduce(
            (accumulator, responseData) => accumulator + responseData.length,
            0
          );
      
          // Store the total number of records in the reservations_count variable
          setPacksCount(totalRecords);
        })
        .catch((error) => {
          console.error('Error fetching data from APIs:', error);
        });

    }, []);



  return (
    <div className='container'>
        <div className='row mb-3'>
            <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
                <div className='statistic-box box-1'>
                    <div className='row'>
                        <div className='col-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                            </svg>
                        </div>
                        <div className='col-9 ml-2'>
                            <div className=''>Visiteurs</div>
                            <div className='stat-number'>{visitorCount}</div>
                            <div className='stat-under-number'>Nombre de visiteurs depuis la creation du site web</div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
                <div className='statistic-box box-2'>
                <div className='row'>
                        <div className='col-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-ticket-perforated-fill" viewBox="0 0 16 16">
                            <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4-1v1h1v-1H4Zm1 3v-1H4v1h1Zm7 0v-1h-1v1h1Zm-1-2h1v-1h-1v1Zm-6 3H4v1h1v-1Zm7 1v-1h-1v1h1Zm-7 1H4v1h1v-1Zm7 1v-1h-1v1h1Zm-8 1v1h1v-1H4Zm7 1h1v-1h-1v1Z"/>
                        </svg>
                        </div>
                        <div className='col-9 ml-2'>
                            <div className=''>Réservation</div>
                            <div className='stat-number'>{reservationsCount}</div>
                            <div className='stat-under-number'>Nombre total de réservation</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
                <div className='statistic-box box-3'>
                <div className='row'>
                        <div className='col-2 mt-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-box-seam-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z"/>
                        </svg>
                        </div>
                        <div className='col-9 ml-2'>
                            <div className=''>Packs</div>
                            <div className='stat-number'>{packsCount}</div>
                            <div className='stat-under-number'>Nombre total de packs</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Statistics;









